import React, { useState, useEffect } from 'react';
import TitleSection from '../../All/Title/TitleSection.js';
import './Promo.css';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { transliterate } from 'transliteration';

const apiUrl = "https://backend.balloonflower.kyiv.ua";

function Promo() {
    const [promoData, setPromoData] = useState([]);
    const [productData, setProductData] = useState([]);

    useEffect(() => {
        // Fetch discount data
        fetch(`${apiUrl}/api/discount`)
            .then(response => response.json())
            .then(data => setPromoData(data))
            .catch(error => console.error('Error fetching discount data:', error));

        // Fetch product data
        fetch(`${apiUrl}/api/products`)
            .then(response => response.json())
            .then(data => setProductData(data.products))
            .catch(error => console.error('Error fetching product data:', error));
    }, []);

    const getProductById = (id) => {
        return productData.find(product => product.id === id);
    };

    return (
        <>
            <section className='HomePromo'>
                <div className='container'>
                    <div className='Promo wrapper'>
                        <TitleSection title='Акційні пропозиції' />
                        <Swiper
                            modules={[Scrollbar]}
                            spaceBetween={20}
                            slidesPerView={1}
                            scrollbar={{ draggable: true }}
                            breakpoints={{
                                640: { slidesPerView: 2 },
                                768: { slidesPerView: 3 },
                                1024: { slidesPerView: 4 },
                            }}>
                            {promoData.map((promoItem, index) => {
                                const product = getProductById(promoItem.product_id);
                                const transliteratedTitle = product ? transliterate(product.title) : '';

                                return (
                                    <SwiperSlide key={index}>
                                        <a href={`/catalog/${encodeURIComponent(transliteratedTitle)}`} className=''>
                                            <img src={`${apiUrl}/uploads/${promoItem.photo}`} alt='' />
                                            <div className='PromoSlideContent'>
                                                <span className='PromoPrice'>{promoItem.discount}%</span>
                                                <p className='PromoText'>{promoItem.title}</p>
                                            </div>
                                        </a>
                                    </SwiperSlide>
                                );
                            })}
                        </Swiper>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Promo;