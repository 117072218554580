import React, { createContext, useState } from 'react';

// Создаем контекст
export const PageContext = createContext();

// Провайдер контекста
export const PageProvider = ({ children }) => {
    const [currentPage, setCurrentPage] = useState(1);

    const resetPage = () => {
        setCurrentPage(1);
    };

    return (
        <PageContext.Provider value={{ currentPage, setCurrentPage, resetPage }}>
            {children}
        </PageContext.Provider>
    );
};
