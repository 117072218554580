import Contact from '../../Components/All/Contact/Contact'
import Flovers from '../../Components/VisualCatalog/Flovers/Flovers'
import Foiled from '../../Components/VisualCatalog/Foiled/Foiled'
import ForWhom from '../../Components/VisualCatalog/ForWhom/ForWhom'
import Latex from '../../Components/VisualCatalog/Latex/Latex'
import ReadySets from '../../Components/VisualCatalog/ReadySets/ReadySets'
import './Search.css'


function Search() {

  return (
  <>
    <ReadySets />
    <ForWhom />
    <Foiled />
    <Latex />
    <Flovers />
    <Contact />
  </>
  )
}

export default Search