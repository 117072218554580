
import BasicForm from '../../Forms/BasicForm/BasicForm'
import SocialIcon from '../SocialIcon/SocialIcon'
import './Contact.css'


function Contact() {

  return (
  <section className='Contact'>
    <div className="ContactMap">
        <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d162571.50438198267!2d30.424877502101452!3d50.456377275707084!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40d4cf4ee15a4505%3A0x764931d2170146fe!2z0JrQuNC10LIsIDAyMDAw!5e0!3m2!1sru!2sua!4v1715242020055!5m2!1sru!2sua"
        // width="140%"
        // height="100%"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
    </div>
    <div className='container'>
        <div className='ContactContent'>
            <h2>Завжди на звязку</h2>
            <p>Зв'яжіться з нами за допомогою форми зворотнього зв'язку нижче або через наші соціальні мережі. Ми швидко відповімо на ваші запитання та надамо необхідну інформацію.</p>
            <BasicForm />
            <SocialIcon />
        </div>
    </div>
  </section>
 
  )
}

export default Contact