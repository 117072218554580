import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@mui/material";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import ConfirmDialog from "../../../../Components/Admin/ConfirmDialog/ConfirmDialog";
import SportsScoreIcon from "@mui/icons-material/SportsScore";

export default function Request() {
  const [requests, setRequests] = useState([]);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const apiUrl = "https://backend.balloonflower.kyiv.ua";
  const token = localStorage.getItem("auth_token");

  useEffect(() => {
    fetch(`${apiUrl}/api/requests`)
      .then((res) => res.json())
      .then((data) => setRequests(data.requests));
  }, []);

  const handleDeleteRequest = (id) => {
    fetch(`${apiUrl}/api/requests/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
    })
      .then(() => {
        fetch(`${apiUrl}/api/requests`)
          .then((res) => res.json())
          .then((data) => setRequests(data.requests));
        setConfirmDialogOpen(false);
        setDeleteId(null);
      })
      .catch((error) => console.error("Error deleting request:", error));
  };

  const openConfirmDialog = (id) => {
    setDeleteId(id);
    setConfirmDialogOpen(true);
  };

  const closeConfirmDialog = () => {
    setConfirmDialogOpen(false);
    setDeleteId(null);
  };

  const confirmDelete = () => {
    if (deleteId) {
      handleDeleteRequest(deleteId);
    }
  };

  return (
    <>
      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center gap-3">
          <h4>Заказы</h4>
        </div>
      </div>
      <TableContainer component={Paper} title="Заказы">
        <Table sx={{ minWidth: 650 }} title="Заказы" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>№</TableCell>
              <TableCell align="right">Имя</TableCell>
              <TableCell align="right">Номер</TableCell>
              <TableCell align="right">Комментарий</TableCell>
              <TableCell align="right">Общая сумма</TableCell>
              <TableCell align="right">Способ доставки</TableCell>
              <TableCell align="right">Способ оплаты</TableCell>
              <TableCell align="right">Время заявки</TableCell>
              <TableCell align="right">Действие</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {requests.map((row, index) => (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {index + 1}
                </TableCell>
                <TableCell align="right">
                  <Link to={`/admin/requests/${row.id}`} className="underline">
                    {row.name}
                  </Link>
                </TableCell>
                <TableCell align="right">{row.phone}</TableCell>
                <TableCell align="right">{row.comment}</TableCell>
                <TableCell align="right">{row.pricee}</TableCell>
                <TableCell align="right">
                  {row.delivery_type === 1 ? "Самовивіз" : "Доставка"}
                </TableCell>
                <TableCell align="right">
                  {row.payment_type === 1
                    ? "Оплата за реквізитами"
                    : "Готівка - (Якщо самовивіз)"}
                </TableCell>
                <TableCell align="right">{row.created_at}</TableCell>
                <TableCell align="right">
                  <Button
                    onClick={() => openConfirmDialog(row.id)}
                    variant="contained"
                    color={row.status === false ? "info" : "success"}
                    size="small"
                  >
                    {row.status === false ? (
                      <TaskAltIcon />
                    ) : (
                      <SportsScoreIcon />
                    )}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ConfirmDialog
        open={confirmDialogOpen}
        onClose={closeConfirmDialog}
        onConfirm={confirmDelete}
        title="Подтвердите заверщение"
        content="Вы уверены, что хотите завершить этот заказ?"
      />
    </>
  );
}
