import TitleSection from '../../All/Title/TitleSection'
import './Flovers.css'

import PopularArrow from '../../../Assets/Icons/popularArrow.svg'
import FloversCard1 from '../../../Assets/Img/VisualCatalog/Flovers/1.webp'
import FloversCard2 from '../../../Assets/Img/VisualCatalog/Flovers/2.webp'
import FloversCard3 from '../../../Assets/Img/VisualCatalog/Flovers/3.webp'
import FloversCard4 from '../../../Assets/Img/VisualCatalog/Flovers/4.webp'


function Flovers() {
    return (
    <section className='PopularCategory'>
      <div className='container'>
          <TitleSection title="Квіти" />
          <div className='PopularWrapper'>
              <div className='PopularRow'>
                  <div className='PopularCol'>
                      <div className='PopularCard'>
                          <img src={FloversCard1} alt='' className='PopularImg'/>
                          <div className='PopularContent'>
                              <h3 className=''>Монобукети</h3>
                              <a href='/shop/Monobuketi'>
                                  <p>Детальніше</p>
                                  <img src={PopularArrow} alt='' className='PopularIcon'/>
                              </a>
                          </div>
                      </div>
                      <div className='PopularCard'>
                          <img src={FloversCard2} alt='' className='PopularImg'/>
                          <div className='PopularContent'>
                              <h3 className='text-white'>Квіти у коробці</h3>
                              <a href='/shop/Kviti%20v%20korobci'>
                                  <p>Детальніше</p>
                                  <img src={PopularArrow} alt='' className='PopularIcon'/>
                              </a>
                          </div>
                      </div>
                  </div>
                  <div className='PopularCol'>
                  <div className='PopularCard'>
                          <img src={FloversCard3} alt='' className='PopularImg'/>
                          <div className='PopularContent'>
                              <h3 className='text-white'>Букети збірні</h3>
                              <a href='/shop/Buketi%20zbirni'>
                                  <p>Детальніше</p>
                                  <img src={PopularArrow} alt='' className='PopularIcon'/>
                              </a>
                          </div>
                      </div>
                  </div>
              </div>
              <div className='PopularRow'>
                  <div className='PopularCol'>
                      <div className='PopularCard'>
                          <img src={FloversCard4} alt='' className='PopularImg'/>
                          <div className='PopularContent'>
                              <h3 className='text-white'>Міні букети</h3>
                              <a href='/shop/Mini%20buketi'>
                                  <p>Детальніше</p>
                                  <img src={PopularArrow} alt='' className='PopularIcon'/>
                              </a>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    </section>
    )
  }
  
  export default Flovers