

import { Link } from 'react-router-dom'
import './FilterBar.css'
import FilterSelect from './FilterSelect/FilterSelect'
import FilterSlider from './FilterSlider/FilterSlider'


function FilterBar() {
  return (
  <div className='FilterBar '>
    <div className=' flex gap-[5px] justify-between flex-wrap'>
      <div className='flex gap-[5px]'>
        <Link to="/shop" className='filter__btn active'>Всі товари</Link>
      </div>
      <div>
      <FilterSelect />
      </div>
    </div>
    <div className='w-full flex justify-between lg:justify-end gap-[30px] pl-[10px] sm:pl-0'>
        <FilterSlider />
        <FilterSelect isHidden />
    </div>
  </div>
  )
}

export default FilterBar
