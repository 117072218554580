import Contact from "../../Components/All/Contact/Contact";
import DeliveryOffer from "../../Components/All/DeliveryOffer/DeliveryOffer";
import Offer2 from "../../Components/All/Offer/Offer2";
import PopularCategory from "../../Components/All/PopularCategory/PopularCategory";
import Shop from "../../Components/Shops/Shop/Shop";
import Flovers from "../../Components/VisualCatalog/Flovers/Flovers";

import DeliveryImg2 from '../../Assets/Img/DeliveryOffer/delivery2.webp'



function Catalog() {
    return (
      <>
        <Shop />
        <Offer2 />
        <PopularCategory />
        <Flovers />
        <DeliveryOffer img={DeliveryImg2}/>
        <Contact />
      </>
    )
}

export default Catalog;
