import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  IconButton,
} from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import AddShopProduct from "../../../Components/Admin/AddShopProduct/AddShopProduct";
import ConfirmDialog from "../../../Components/Admin/ConfirmDialog/ConfirmDialog";
import { Link } from "react-router-dom";

function ShopProductList() {
  const [products, setProducts] = useState([]);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [productToDelete, setProductToDelete] = useState(null);
  const [addProductOpen, setAddProductOpen] = useState(false);

  const fetchProducts = () => {
    fetch("https://backend.balloonflower.kyiv.ua/api/products")
      .then((response) => response.json())
      .then((data) => setProducts(data.products))
      .catch((error) => console.error("Error fetching products:", error));
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  const handleDeleteProduct = (product) => {
    setProductToDelete(product);
    setConfirmDialogOpen(true);
  };

  const confirmDeleteProduct = () => {
    fetch(`https://backend.balloonflower.kyiv.ua/api/products/${productToDelete.id}`, {
      method: "DELETE",
      headers: {
        token: localStorage.getItem("auth_token"),
      },
    })
      .then(() => {
        fetchProducts();
        setConfirmDialogOpen(false);
        setProductToDelete(null);
      })
      .catch((error) => console.error("Error deleting product:", error));
  };

  const closeConfirmDialog = () => {
    setConfirmDialogOpen(false);
    setProductToDelete(null);
  };

  const handleAddProduct = (newProduct) => {
    fetch("https://backend.balloonflower.kyiv.ua/api/products", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        token: localStorage.getItem("auth_token"),
      },
      body: JSON.stringify(newProduct),
    })
      .then((response) => response.json())
      .then(() => {
        fetchProducts();
        setAddProductOpen(false);
      })
      .catch((error) => console.error("Error adding product:", error));
  };

  const handleEditProduct = (editedProduct) => {
    fetch("https://backend.balloonflower.kyiv.ua/api/products", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        token: localStorage.getItem("auth_token"),
      },
      body: JSON.stringify(editedProduct),
    })
      .then((response) => response.json())
      .then(() => {
        fetchProducts();
        setAddProductOpen(false);
      })
      .catch((error) => console.error("Error adding product:", error));
  };

  return (
    <section>
      <div className="container">
        <div className="flex flex-col gap-[40px]">
          <div className="HEAD flex justify-between">
            <div>
              <h2>Shop Products</h2>
              <p>List of products available in the shop</p>
            </div>
            <div>
              <AddShopProduct
                open={addProductOpen}
                onClose={() => setAddProductOpen(false)}
                onSave={handleAddProduct}
                initialData={{
                  title: "",
                  description: "",
                  price: "",
                  price_old: "",
                  category_id: "",
                }}
                buttonText="Add New Product"
              />
            </div>
          </div>

          <div>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Title</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Price</TableCell>
                    <TableCell>Old Price</TableCell>
                    <TableCell>Category</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {products.map((product) => (
                    <TableRow key={product.id}>
                      <TableCell>
                        <Link
                          className="underline"
                          to={`/admin/product/${product.id}`}
                        >
                          {product.title}
                        </Link>
                      </TableCell>
                      <TableCell>{product.description}</TableCell>
                      <TableCell>{product.price}</TableCell>
                      <TableCell>{product.price_old}</TableCell>
                      <TableCell>{product.category}</TableCell>

                      <TableCell>
                        <div className="flex items-center gap-3">
                          <AddShopProduct
                            initialData={product}
                            onSave={handleEditProduct}
                            buttonText="Edit"
                          />
                          <IconButton
                            onClick={() => handleDeleteProduct(product)}
                          >
                            <DeleteOutlineOutlinedIcon sx={{ color: "red" }} />
                          </IconButton>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>

      <ConfirmDialog
        open={confirmDialogOpen}
        onClose={closeConfirmDialog}
        onConfirm={confirmDeleteProduct}
        title="Confirm Delete"
        content="Are you sure you want to delete this product?"
      />
    </section>
  );
}

export default ShopProductList;
