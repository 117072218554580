
// import './CardBallon.css'

// function CardBallon({ color, isActive }) { // Принимаем параметр isActive
//     return (
//       <svg className={`CardBallon ${isActive ? 'active' : ''}`} width="20" height="26" viewBox="0 0 20 26" fill="none" xmlns="http://www.w3.org/2000/svg">
//           <path d="M9.96305 20.7479C5.76475 20.7173 3.22393 16.8264 2.70784 16.0358C2.2471 15.3304 -0.134406 10.5009 1.6607 6.61148C3.0519 3.5987 6.08488 1.07658 9.66387 1.00178C13.8734 0.917262 17.2811 3.85226 18.4898 7.20985C19.6252 10.3625 18.5646 13.1591 18.1906 14.0911C17.1225 16.7785 14.2429 20.7808 9.96305 20.7479Z" fill={color} stroke="#500056" strokeWidth="1.5" strokeMiterlimit="10" />
//           <path d="M8.69008 20.5977L7.34375 24.4123L12.6543 24.3375C12.1811 23.1158 11.7073 21.8941 11.2331 20.6725" stroke="#500056" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
//           <path d="M10.8242 4.74121C11.9319 4.9339 12.9547 5.45938 13.7564 6.24771C14.5581 7.03605 15.1007 8.04984 15.312 9.15417" stroke="#500056" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
//       </svg>
//     )
//   }
  
//   export default CardBallon
import './CardBallon.css';

function CardBallon({ color, isActive }) {
  return (
    <svg className={`CardBallon ${isActive ? 'active' : ''}`} width="20" height="26" viewBox="0 0 20 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%">
          <stop offset="10%" style={{ stopColor: 'red', stopOpacity: 1 }} />
          <stop offset="20%" style={{ stopColor: '#ff8000', stopOpacity: 1 }} />
          {/* <stop offset="20%" style={{ stopColor: '#ff0', stopOpacity: 1 }} /> */}
          <stop offset="30%" style={{ stopColor: '#80ff00', stopOpacity: 1 }} />
          {/* <stop offset="40%" style={{ stopColor: '#0f0', stopOpacity: 1 }} /> */}
          <stop offset="50%" style={{ stopColor: '#00ff80', stopOpacity: 1 }} />
          {/* <stop offset="60%" style={{ stopColor: '#0ff', stopOpacity: 1 }} /> */}
          {/* <stop offset="70%" style={{ stopColor: '#00f', stopOpacity: 1 }} /> */}
          <stop offset="80%" style={{ stopColor: '#8000ff', stopOpacity: 1 }} />
          <stop offset="90%" style={{ stopColor: '#ff0080', stopOpacity: 1 }} />
          <stop offset="100%" style={{ stopColor: 'red', stopOpacity: 1 }} />
        </linearGradient>
      </defs>
      <path
        d="M9.96305 20.7479C5.76475 20.7173 3.22393 16.8264 2.70784 16.0358C2.2471 15.3304 -0.134406 10.5009 1.6607 6.61148C3.0519 3.5987 6.08488 1.07658 9.66387 1.00178C13.8734 0.917262 17.2811 3.85226 18.4898 7.20985C19.6252 10.3625 18.5646 13.1591 18.1906 14.0911C17.1225 16.7785 14.2429 20.7808 9.96305 20.7479Z"
        fill={'url(#gradient)'}
        stroke="#500056"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M9.96305 20.7479C5.76475 20.7173 3.22393 16.8264 2.70784 16.0358C2.2471 15.3304 -0.134406 10.5009 1.6607 6.61148C3.0519 3.5987 6.08488 1.07658 9.66387 1.00178C13.8734 0.917262 17.2811 3.85226 18.4898 7.20985C19.6252 10.3625 18.5646 13.1591 18.1906 14.0911C17.1225 16.7785 14.2429 20.7808 9.96305 20.7479Z"
        fill={color}
        stroke="#500056"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      
      <path
        d="M8.69008 20.5977L7.34375 24.4123L12.6543 24.3375C12.1811 23.1158 11.7073 21.8941 11.2331 20.6725"
        stroke="#500056"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.8242 4.74121C11.9319 4.9339 12.9547 5.45938 13.7564 6.24771C14.5581 7.03605 15.1007 8.04984 15.312 9.15417"
        stroke="#500056"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default CardBallon;
