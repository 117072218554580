import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { transliterate } from 'transliteration';
import './SearchInput.css';
import { Autocomplete, IconButton, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const apiUrl = "https://backend.balloonflower.kyiv.ua";
const token = localStorage.getItem("auth_token");

function SearchInput() {
    const [products, setProducts] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await fetch(`${apiUrl}/api/products`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                const data = await response.json();
                setProducts(data.products);
            } catch (error) {
                console.error("Error fetching products:", error);
            }
        };

        fetchProducts();
    }, []);

    const handleSearchChange = (event, value) => {
        setSearchTerm(value);
    };

    const handleSearchClick = () => {
        const exactMatch = products.find(product => product.title.toLowerCase() === searchTerm.toLowerCase());

        if (exactMatch) {
            // Транслитерация названия продукта и переход на страницу деталей продукта
            const transliteratedTitle = transliterate(exactMatch.title);
            navigate(`/catalog/${encodeURIComponent(transliteratedTitle)}`);
        } else {
            // Фильтруем продукты и обновляем состояние
            const filteredProducts = products.filter(product =>
                product.title.toLowerCase().includes(searchTerm.toLowerCase())
            );
        }
    };

    const handleProductSelect = (event, value) => {
        if (value) {
            const transliteratedTitle = transliterate(value.label);
            navigate(`/catalog/${encodeURIComponent(transliteratedTitle)}`);
        }
    };

    const filteredProducts = products.filter(product =>
        product.title.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <>
            <Autocomplete
                className='SearchInput'
                disablePortal
                id="combo-box-demo"
                options={filteredProducts.map(product => ({ label: product.title, id: product.id }))}
                onInputChange={handleSearchChange}
                onChange={handleProductSelect} // Добавляем обработчик выбора продукта
                sx={{ width: 300 }}
                renderInput={(params) => 
                    <TextField
                        {...params}
                        label="Введіть назву товару"
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <IconButton onClick={handleSearchClick}>
                                    <SearchIcon />
                                </IconButton>
                            ),
                        }}
                    />
                }
            />
        </>
    );
}

export default SearchInput;
