import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import AddColor from "../../../Components/Admin/AddColor/AddColor";
import ConfirmDialog from "../../../Components/Admin/ConfirmDialog/ConfirmDialog";

function ColorList() {
  const [colors, setColors] = useState([]);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [addColorOpen, setAddColorOpen] = useState(false);
  const apiUrl = 'https://backend.balloonflower.kyiv.ua';
  const token = localStorage.getItem('auth_token');


  useEffect(() => {
    fetch(`${apiUrl}/api/colors`)
      .then((response) => response.json())
      .then((data) => setColors(data))
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const handleDeleteColor = (id) => {
    fetch(`${apiUrl}/api/colors`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "token": `${token}`
      },
      body: JSON.stringify({ id })
    })
      .then(() => {
        setColors(colors.filter((color) => color.id !== id));
        setConfirmDialogOpen(false);
        setDeleteId(null);
      })
      .catch((error) => console.error("Error deleting data:", error));
  };

  const handleSaveColor = (updatedData) => {
    fetch(`${apiUrl}/api/colors`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "token": `${token}`
      },
      body: JSON.stringify(updatedData),
    })
      .then((response) => response.json())
      .then(() => {
        setColors(
          colors.map((color) =>
            color.id === updatedData.id ? updatedData : color
          )
        );
      })
      .catch((error) => console.error("Error updating data:", error));
  };

  const handleAddColor = (newData) => {
    fetch(`${apiUrl}/api/colors`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "token": `${token}`
      },
      body: JSON.stringify(newData),
    })
      .then((response) => response.json())
      .then((newColor) => {
        setColors([...colors, newColor]);
        setAddColorOpen(false);
      })
      .catch((error) => console.error("Error adding data:", error));
  };

  const openConfirmDialog = (id) => {
    setDeleteId(id);
    setConfirmDialogOpen(true);
  };

  const closeConfirmDialog = () => {
    setConfirmDialogOpen(false);
    setDeleteId(null);
  };

  const confirmDelete = () => {
    if (deleteId) {
      handleDeleteColor(deleteId);
    }
  };

  return (
    <>
      <section>
        <div className="container">
          <div className="flex flex-col gap-[40px]">
            <div className="HEAD flex justify-between">
              <div>
                <h2>Кольори</h2>
                <p>Тут зберігаються всі зареєстровані кольори</p>
              </div>
              <div>
                <AddColor
                  open={addColorOpen}
                  onClose={() => setAddColorOpen(false)}
                  onSave={handleAddColor}
                  initialData={{ color: "" }}
                  buttonText="Add New Color"
                  titleText="Add New Color"
                />
              </div>
            </div>
            <div>
              <TableContainer component={Paper}>
                <Table aria-label="colors table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Color</TableCell>
                      <TableCell>Preview</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {colors.map((color) => (
                      <TableRow key={color.id}>
                        <TableCell>{color.color}</TableCell>
                        <TableCell>
                          <div
                            style={{
                              width: "30px",
                              height: "30px",
                              border: "1px solid grey",

                              backgroundColor: color.color,
                            }}
                          ></div>
                        </TableCell>
                        <TableCell>
                          <AddColor initialData={color} onSave={handleSaveColor} />
                          <Button
                            sx={{ marginLeft: "4px", marginTop: "4px" }}
                            variant="outlined"
                            onClick={() => openConfirmDialog(color.id)}
                          >
                            <DeleteOutlineOutlinedIcon />
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </div>

        <ConfirmDialog
          open={confirmDialogOpen}
          onClose={closeConfirmDialog}
          onConfirm={confirmDelete}
          title="Confirm Delete"
          content="Are you sure you want to delete this item?"
        />
      </section>
    </>
  );
}

export default ColorList;
