import './Thanks.css'

import Contact from '../../Components/All/Contact/Contact'

function Thanks() {

  return (
  <>
    <section>
        <div className='container py-[120px]'>
            <div className='flex flex-col gap-[20px] text-center items-center'>
                <h1>Дякую, ваше замовлення прийнято!</h1>
                <p>Наш менеджер зв'яжеться з вами найближчим часом</p>
                <a className='button' href='/'> На головну</a>
            </div>
        </div>
    </section>
    <Contact />
  </>
  )
}

export default Thanks