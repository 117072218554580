import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DeleteOutlineOutlined from "@mui/icons-material/DeleteOutlineOutlined";
import Edit from "@mui/icons-material/Edit";
import { Link, useParams } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import { DialogActions, Button, Dialog, DialogTitle } from "@mui/material";
import AddProductColor from "../../../../Components/Admin/AddProductColor/AddProductColor";

export default function BasicTable() {
  const { productId } = useParams("productId");
  const [colors, setColors] = useState([]);
  const [open, setOpen] = useState(false);
  const [delColor, setDelColor] = useState(null);

  const handleClickOpen = (index) => {
    setDelColor(index);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setDelColor(null);
  };

  const deleteColor = () => {
    fetch(`https://backend.balloonflower.kyiv.ua/api/product/color/${colors[delColor].id}`, {
      method: "DELETE",
      headers: {
        token: localStorage.getItem("auth_token"),
      },
    }).then(() => {
      colors.splice(delColor, 1);
      handleClose();
    });
  };

  useEffect(() => {
    fetch(`https://backend.balloonflower.kyiv.ua/api/product/color/${productId}`)
      .then((res) => res.json())
      .then((data) => setColors(data));
  }, [productId]);
  return (
    <>
      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center gap-3">
          <Link to="/admin/shopproductlist">
            <Button variant="contained" color="primary" size="small">
              Назад
            </Button>
          </Link>
          <h4>Цвета продукта</h4>
        </div>
        <AddProductColor productId={productId} buttonText={"Добавить цвет"} />
      </div>
      <TableContainer component={Paper} title="Цвета продукта">
        <Table
          sx={{ minWidth: 650 }}
          title="Цвета продукта"
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell>№</TableCell>
              <TableCell align="right">Цвет</TableCell>
              <TableCell align="right">Фотография</TableCell>
              <TableCell align="right">Действие</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {colors.map((row, index) => (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {index + 1}
                </TableCell>
                <TableCell align="right">
                  <div className="flex items-center justify-end gap-2">
                    <div
                      className={`w-10 h-10`}
                      style={{ backgroundColor: row.color }}
                    ></div>
                    {row.color}
                  </div>
                </TableCell>
                <TableCell align="right">
                  <div className="flex justify-end">
                    <img
                      className="w-[60px] h-[60px] object-cover"
                      src={`https://backend.balloonflower.kyiv.ua/uploads/${row.photo}`}
                      alt="color"
                    />
                  </div>
                </TableCell>
                <TableCell align="right">
                  <div className="flex justify-end gap-2">
                    <Button
                      onClick={handleClickOpen.bind(this, index)}
                      variant="contained"
                      color="error"
                      size="small"
                    >
                      <DeleteOutlineOutlined />
                    </Button>
                    <AddProductColor
                      product_color={row.id}
                      productId={productId}
                      buttonText={"Изменить цвет"}
                    />
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className="font-bold">
          {"Вы действительно хотите удалить цвет? " + colors[delColor]?.color}
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="info"
            variant="contained"
            size="small"
          >
            Нет
          </Button>
          <Button
            onClick={deleteColor}
            color="error"
            variant="contained"
            autoFocus
            size="small"
          >
            Да
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
