import React, { useState } from 'react';
import TitleSection from '../../All/Title/TitleSection';
import './Shop.css';
import Sidebar from './Sidebar/Sidebar';
import FilterBar from '../FilterBar/FilterBar';
import ShopCard from './ShopCard/ShopCard';
import { PriceFilterProvider } from '../../../context/PriceFilterContext';
import SearchInput from '../../Header/SearchInput/SearchInput';

function Shop() {
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const resetPage = () => {
    setCurrentPage(1);
  };
  return (
    <PriceFilterProvider> {/* Оборачиваем в провайдер контекста */}
      <section className='Shop'>
        <div className='container'>
          <TitleSection title='Каталог' />
          <div className='flex gap-[20px]'>
            <Sidebar resetPage={resetPage} />
            <div className='w-full'>
              <FilterBar />
              <ShopCard resetPage={resetPage}/>
            </div>
          </div>
        </div>
      </section>
    </PriceFilterProvider>
  );
}

export default Shop;