import React, { useState, useEffect, useContext } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './Sidebar.css';
import { useNavigate } from 'react-router-dom';
import { transliterate } from 'transliteration';
import { PageContext } from '../../../../context/PageContext';

const apiUrl = "https://backend.balloonflower.kyiv.ua";
const token = localStorage.getItem("auth_token");

function Sidebar() {
    const [sidebarData, setSidebarData] = useState([]);
    const [activeSubcategory, setActiveSubcategory] = useState(null);
    const navigate = useNavigate();
    const { resetPage } = useContext(PageContext); // Используем resetPage из контекста

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${apiUrl}/api/categories`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                
                const formattedData = formatData(data);
                setSidebarData(formattedData);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    const formatData = (data) => {
        const categories = data.category.map(cat => ({
            ...cat,
            items: data.subcategory.filter(sub => Number(sub.parent_id) === cat.id)
        }));
        return categories;
    };
    
    const handleSubcategoryClick = (subcategoryTitle) => {
        resetPage(); // Сбрасываем страницу
        setActiveSubcategory(subcategoryTitle);
        const transliteratedTitle = transliterate(subcategoryTitle);
        navigate(`/shop/${encodeURIComponent(transliteratedTitle)}`);
        window.scrollTo({ top: 200, behavior: 'smooth' });
    };

    return (
        <div className='Sidebar'>
            {sidebarData.map((sidebarItem, index) => (
                <Accordion defaultExpanded={index === 0} key={index}>
                    <AccordionSummary
                        className='SidebarTitle'
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel${index}-content`}
                        id={`panel${index}-header`}
                    >
                        <Typography>{sidebarItem.title}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className='SidebarItems'>
                            {sidebarItem.items.map((item, itemIndex) => (
                                <div 
                                    key={itemIndex} 
                                    className={`SidebarItem ${activeSubcategory === item.title ? 'active' : ''}`} 
                                    style={{ cursor: 'pointer' }} 
                                    onClick={() => handleSubcategoryClick(item.title)}
                                >
                                    {item.title}
                                </div>
                            ))}
                        </div>
                    </AccordionDetails>
                </Accordion>
            ))}
        </div>
    );
}

export default Sidebar;
