import { useState, useEffect, useContext } from 'react';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { transliterate } from 'transliteration';
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import './CatalogBtn.css';
import ManuCatalog from '../../../Assets/Icons/manuCatalog.svg';
import { PageContext } from '../../../context/PageContext';

const apiUrl = "https://backend.balloonflower.kyiv.ua";
const token = localStorage.getItem("auth_token");

export default function CatalogBtn() {
  const [open, setOpen] = useState(false);
  const anchorRef = React.useRef(null);
  const [sidebarData, setSidebarData] = useState([]);
  const navigate = useNavigate();
  const { resetPage } = useContext(PageContext);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${apiUrl}/api/categories`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        const formattedData = formatData(data);
        setSidebarData(formattedData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const formatData = (data) => {
    const categories = data.category.map(cat => ({
      ...cat,
      items: data.subcategory.filter(sub => Number(sub.parent_id) === cat.id)
    }));
    return categories;
  };

  const handleCategoryClick = (categoryTitle) => {
    resetPage();
    const transliteratedTitle = transliterate(categoryTitle);
    navigate(`/shop/${encodeURIComponent(transliteratedTitle)}`);
    setOpen(false);

    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div>
      <Button
        ref={anchorRef}
        id="composition-button"
        aria-controls={open ? 'composition-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        className='CatalogBtn'
      >
        <img src={ManuCatalog} alt='' className='w-[24px] lg:w-[30px] max-w-[30px]' />
        <p>Каталог</p>
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom-start' ? 'left top' : 'left bottom',
            }}
          >
            <Paper className='CatalogBtnOnclick'>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                  style={{ columns: 1 }}
                >
                  {sidebarData.map((sidebarItem, index) => (
                    <div key={index}>
                      <MenuItem 
                        className="MenuItem category" 
                        // onClick={() => handleCategoryClick(sidebarItem.title)}
                      >
                        {sidebarItem.title}
                      </MenuItem>
                      {sidebarItem.items.map((item, itemIndex) => (
                        <MenuItem 
                          key={itemIndex} 
                          onClick={() => handleCategoryClick(item.title)} 
                          className="MenuItem"
                        >
                          {item.title}
                        </MenuItem>
                      ))}
                    </div>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}