import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useBasket } from "../../../context/BasketContext";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import BasketImg from "../../../Assets/Icons/basket.svg";
import EmptyBasket from "./EmptyBasket/EmptyBasket";
import Trash from "../../../Assets/Icons/trash.svg";
import Minus from "../../../Assets/Icons/minus.svg";
import Plus from "../../../Assets/Icons/plus.svg";
import CardBallon from "../Shop/ShopCard/CardBallon/CardBallon";
import "./Basket.css";
import { Badge } from "@mui/material";
import { transliterate } from 'transliteration';
import defoltFoto from '../../../Assets/Img/Shop/defoltFoto.png';

// const apiUrl = "https://backend.balloonflower.kyiv.ua";

function Basket() {
  const [show, setShow] = useState(false);
  const { basket, updateQuantity, removeFromBasket } = useBasket();
  const [totalPrice, setTotalPrice] = useState(0);

  useEffect(() => {
    const total = basket.reduce((sum, item) => sum + item.price * item.quantity, 0);
    setTotalPrice(total);
  }, [basket]);

  const handleClose = () => setShow(false);
  const toggleShow = () => setShow((s) => !s);

  const handleDecreaseQuantity = (index) => {
    if (basket[index].quantity > 1) {
      updateQuantity(index, basket[index].quantity - 1);
    }
  };

  const handleIncreaseQuantity = (index) => {
    updateQuantity(index, basket[index].quantity + 1);
  };

  return (
    <>
      <Button variant="primary" onClick={toggleShow} className="BasketBtn">
        <Badge
          badgeContent={basket.length}  // Здесь basket.length отображает количество товаров в корзине
          sx={{
            '& .MuiBadge-badge': {
              backgroundColor: '#F2CB05',
              color: 'black',
            },
          }}
        >
          <img src={BasketImg} alt="" className="w-[24px] lg:w-[30px] max-w-[30px]" />
        </Badge>
      </Button>
      <Offcanvas
        show={show}
        onHide={handleClose}
        scroll={true}
        backdrop={false}
        placement="end"
        className="OffcanvasBasket"
      >
        <Offcanvas.Header closeButton className="line__bottom">
          <Offcanvas.Title className="text-[36px] text-center w-full pl-[32px]">
            Кошик
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {basket.length === 0 ? (
            <EmptyBasket handleClose={handleClose} />
          ) : (
            <>
              {basket.map((item, index) => (
                <div key={index} className="BasketItem">
                  {/* <img className="BasketItem__img" src={item.selectedColor.photoColor} alt="" /> */}
                  <img
                    className="BasketItem__img"
                    src={item.selectedColor.photo ? `https://backend.balloonflower.kyiv.ua/uploads/${item.selectedColor.photo}` : defoltFoto}
                    alt={item.title}
                  />
                  <div className="BasketItem__content">
                    <div className="BasketItem__content-top">
                      <Link to={`/catalog/${encodeURIComponent(transliterate(item.title))}`} className="BasketItem__title">
                        {item.title}
                      </Link>
                      <img
                        className="BasketItem__trash"
                        src={Trash}
                        alt=""
                        onClick={() => removeFromBasket(index)}
                      />
                    </div>
                    <div className="ColorPanel">
                      <div className="relative flex items-center">
                        <CardBallon color={item.selectedColor.color} />
                      </div>
                      <div className="ColorSwith">
                        <div className="ColorBtn dark" onClick={() => handleDecreaseQuantity(index)}>
                          <img src={Minus} alt="" />
                        </div>
                        <span className='w-[28px] text-center'>{item.quantity}</span>
                        <div className="ColorBtn" onClick={() => handleIncreaseQuantity(index)}>
                          <img src={Plus} alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="ProductDetail__bottom">
                      <div className="ProductDetail__price">
                        <p className="actual__price">{item.price} грн/шт.</p>
                      </div>
                    </div>
                    <div className="flex justify-between">
                      <p className="ProductDetail__card-price">Ціна:</p>
                      <p className="ProductDetail__card-price"><span>{item.price * item.quantity}</span> грн</p>
                    </div>
                  </div>
                </div>
              ))}
              <div className="BasketAll">
                <div className="BasketAll__priseAll">
                  <p className="BasketAll__priseAll-text">До сплати без доставки:</p>
                  <p className="BasketAll__priseAll-price"><span>{totalPrice}</span> грн</p>
                </div>
                <a className="button" href="/checkout" onClick={handleClose}>Оформити замовленняя</a>
              </div>
            </>
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default Basket;