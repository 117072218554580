import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import "./AddCategory.css";

function AddCategory({ initialData = {}, onSave, buttonText = "Edit" }) {
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    id: initialData.id || "",
    title: initialData.title || "",
  });
  const [formErrors, setFormErrors] = useState({
    title: false,
  });

  useEffect(() => {
    if (open) {
      setFormData(initialData);
    }
  }, [open, initialData]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSave = () => {
    const errors = {};
    let hasErrors = false;
    if (!formData.title) {
      errors.title = true;
      hasErrors = true;
    } else {
      errors.title = false;
    }
    setFormErrors(errors);

    if (!hasErrors) {
      onSave(formData);
      handleClose();
    }
  };

  return (
    <div>
      <Button
        sx={{ marginLeft: "4px", marginTop: "4px" }}
        variant="outlined"
        onClick={handleClickOpen}
      >
        {buttonText}
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{buttonText} Category</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            name="title"
            label="Category Title"
            type="text"
            fullWidth
            value={formData.title}
            onChange={handleChange}
            required={true}
            error={formErrors.title}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSave}>Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AddCategory;