import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import "./AddSlider.css";

function AddSlider({
  initialData = {},
  onSave,
  buttonText = "Edit",
  slider_id,
}) {
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    id: initialData.id || "",
    photo: initialData.photo || "",
    title: initialData.title || "",
    description: initialData.text || "",
  });
  const [edited, setEdited] = useState(false);

  const [file, setFile] = useState(null);
  const handleFileChange = (event) => {
    setEdited(true);
    setFile(event.target.files[0]);
  };

  useEffect(() => {
    if (open) {
      setFormData(initialData);
    }

    if (slider_id) {
      setFile(initialData.photo || null);
    }
  }, [open, initialData, slider_id]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setFile(null);
    setEdited(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSave = () => {
    const data = new FormData();
    if (slider_id) {
      data.append("id", formData.id);
    }
    data.append("title", formData.title);
    data.append("description", formData.description);
    data.append("photo", file);

    onSave(data);
    handleClose();
  };

  return (
    <div>
      <Button
        sx={{ marginLeft: "4px", marginTop: "4px" }}
        variant="outlined"
        onClick={handleClickOpen}
      >
        {buttonText}
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{buttonText} Item</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            name="title"
            label="Титул"
            type="text"
            fullWidth
            value={formData.title}
            onChange={handleChange}
            required={true}
          />
          <TextField
            margin="dense"
            name="description"
            label="Описание"
            type="text"
            fullWidth
            value={formData.description}
            onChange={handleChange}
            required={true}
          />
          {file && (!slider_id || edited) ? (
            <img
              className="w-full h-40 object-contain"
              src={URL.createObjectURL(file)}
              alt="preview"
            />
          ) : file ? (
            <img
              className="w-full h-40 object-contain"
              src={`https://backend.balloonflower.kyiv.ua/uploads/${file}`}
              alt="preview"
            />
          ) : null}
          <Button
            component="label"
            variant="contained"
            fullWidth
            startIcon={<CloudUploadIcon />}
          >
            Загрузить изображение
            <input
              onChange={handleFileChange}
              name="photo"
              className="hidden"
              type="file"
            />
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSave}>Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AddSlider;
