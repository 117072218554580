import BasicForm from '../../Forms/BasicForm/BasicForm'
import './Offer2.css'
import OfferImg2 from "../../../Assets/Img/Offer/2.webp"


function Offer2() {

  return (
  <section className='Offer2'>
    <div className='container'>
        <img src={OfferImg2} alt='' className='OfferImg'/>
        <div className='OfferContent'>
            <h2>Замовити для свята Надувні Кульки та Сети з Кульками!</h2>
            <p>Залиште заявку і ми допоможемо підібрати серед великого вибору надувних кульок у різних кольорах, формах і розмірах. Від класичних кульок до надувних фігурок і арок - у нас є все необхідне для створення яскравого та незабутнього свята.</p>
            <BasicForm />
        </div>
    </div>
  </section>
  )
}

export default Offer2