import { TextField, Button } from "@mui/material";
import { useRef } from "react";
import LockIcon from "@mui/icons-material/Lock";
import "./Login.css";

function Login() {
  const username = useRef(null);
  const password = useRef(null);

  const apiUrl = "https://backend.balloonflower.kyiv.ua";
  const tryLogin = async () => {
    try {
      console.log(username.current.value, password.current.value);
      let data = await fetch(apiUrl + "/api/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: username.current.value,
          password: password.current.value,
        }),
      });

      data = await data.json()

      if (data.status === 200) {
        localStorage.setItem("auth_token", await data.token)   
        window.location.href = '/admin'     
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <section className="login-section">
        <div className="login-container">
          <div className="login-inner">
            <div className="login-top">
              <p className="login-title">Login</p>
              <LockIcon color="secondary" />
            </div>
            <TextField
              inputRef={username}
              color="secondary"
              variant="outlined"
              label="Login"
            />
            <TextField
              inputRef={password}
              color="secondary"
              variant="outlined"
              type="password"
              label="Password"
            />
            <Button onClick={tryLogin} variant="contained" color="secondary">
              Login
            </Button>
          </div>
        </div>
      </section>
    </>
  );
}

export default Login;
