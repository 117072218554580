import TitleSection from '../../All/Title/TitleSection'

import PopularArrow from '../../../Assets/Icons/popularArrow.svg'
import FoiledCard1 from "../../../Assets/Img/VisualCatalog/Foiled/1.webp"
import FoiledCard2 from "../../../Assets/Img/VisualCatalog/Foiled/2.webp"
import FoiledCard3 from "../../../Assets/Img/VisualCatalog/Foiled/3.webp"
import FoiledCard4 from "../../../Assets/Img/VisualCatalog/Foiled/4.webp"
import FoiledCard5 from "../../../Assets/Img/VisualCatalog/Foiled/5.webp"
import FoiledCard6 from "../../../Assets/Img/VisualCatalog/Foiled/6.webp"
import FoiledCard7 from "../../../Assets/Img/VisualCatalog/Foiled/7.webp"
import FoiledCard8 from "../../../Assets/Img/VisualCatalog/Foiled/8.webp"
import FoiledCard9 from "../../../Assets/Img/VisualCatalog/Foiled/9.webp"

function Foiled() {
    return (
        <section className='PopularCategory'>
            <div className='container'>
                <TitleSection title="Фольговані" />
                <div className='PopularWrapper'>
                    <div className='PopularRow'>
                        <div className='PopularCol'>
                            <div className='PopularCard'>
                                <img src={FoiledCard1} alt='' className='PopularImg'/>
                                <div className='PopularContent'>
                                    <h3 className=''>Тваринки</h3>
                                    <a href='/shop/Tvarinki'>
                                        <p>Детальніше</p>
                                        <img src={PopularArrow} alt='' className='PopularIcon'/>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='PopularRow'>
                        <div className='PopularCol'>
                            <div className='PopularCard'>
                                <img src={FoiledCard2} alt='' className='PopularImg'/>
                                <div className='PopularContent'>
                                    <h3 className=''>Ходячі</h3>
                                    <a href='/shop/Hodyachi%20figurki'>
                                        <p>Детальніше</p>
                                        <img src={PopularArrow} alt='' className='PopularIcon'/>
                                    </a>
                                </div>
                            </div>
                            <div className='PopularCard'>
                                <img src={FoiledCard3} alt='' className='PopularImg'/>
                                <div className='PopularContent'>
                                    <h3 className=''>Цифри</h3>
                                    <a href='/shop/Cifri'>
                                        <p>Детальніше</p>
                                        <img src={PopularArrow} alt='' className='PopularIcon'/>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className='PopularCol'>
                            <div className='PopularCard'>
                                <img src={FoiledCard4} alt='' className='PopularImg'/>
                                <div className='PopularContent'>
                                    <h3 className=''>Супергерої</h3>
                                    <a href='/shop/Supergeroyi'>
                                        <p>Детальніше</p>
                                        <img src={PopularArrow} alt='' className='PopularIcon'/>
                                    </a>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div className='PopularWrapper mt-[14px] sm:mt-[20px]'>
                    <div className='PopularRow'>
                        <div className='PopularCol'>
                            <div className='PopularCard'>
                                <img src={FoiledCard5} alt='' className='PopularImg'/>
                                <div className='PopularContent'>
                                    <h3 className=''>Без малюнка</h3>
                                    <a href='/shop/Bez%20malyunka'>
                                        <p>Детальніше</p>
                                        <img src={PopularArrow} alt='' className='PopularIcon'/>
                                    </a>
                                </div>
                            </div>
                            <div className='PopularCard'>
                                <img src={FoiledCard6} alt='' className='PopularImg'/>
                                <div className='PopularContent'>
                                    <h3 className='text-white'>Техніка</h3>
                                    <a href='/shop/Tehnika'>
                                        <p>Детальніше</p>
                                        <img src={PopularArrow} alt='' className='PopularIcon'/>
                                    </a>
                                </div>
                            </div>
                        </div>                        
                        <div className='PopularCol'>
                            <div className='PopularCard'>
                                <img src={FoiledCard7} alt='' className='PopularImg'/>
                                <div className='PopularContent'>
                                    <h3 className=''>З малюнком</h3>
                                    <a href='/shop/Z%20malyunkom'>
                                        <p>Детальніше</p>
                                        <img src={PopularArrow} alt='' className='PopularIcon'/>
                                    </a>
                                </div>
                            </div>
                            <div className='PopularCard'>
                                <img src={FoiledCard8} alt='' className='PopularImg'/>
                                <div className='PopularContent'>
                                    <h3 className=''>Мультфільми</h3>
                                    <a href='/shop/Z%20multfilmiv'>
                                        <p>Детальніше</p>
                                        <img src={PopularArrow} alt='' className='PopularIcon'/>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='PopularRow'>
                        <div className='PopularCol'>
                            <div className='PopularCard'>
                                <img src={FoiledCard9} alt='' className='PopularImg'/>
                                <div className='PopularContent'>
                                    <h3 className=''>Единороги</h3>
                                    <a href='/shop/Edinorigi'>
                                        <p>Детальніше</p>
                                        <img src={PopularArrow} alt='' className='PopularIcon'/>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
  
export default Foiled