import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { TextField, Button, Container, Typography, Box } from '@mui/material';

const apiUrl = "https://backend.balloonflower.kyiv.ua";
const token = localStorage.getItem("auth_token");

function ToCode() {
  const [scriptContent, setScriptContent] = useState('');
  const [savedScript, setSavedScript] = useState('');

  useEffect(() => {
    // Загружаем скрипт с сервера при загрузке компонента
    fetch(`${apiUrl}/api/get-script`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setSavedScript(data.scriptContent);
        setScriptContent(data.scriptContent); // Обновляем поле для отображения сохраненного скрипта
      })
      .catch((error) => console.error('Error fetching script:', error));
  }, []);

  const handleScriptChange = (event) => {
    setScriptContent(event.target.value);
  };

  const handleSaveScript = () => {
    fetch(`${apiUrl}/api/save-script`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({ scriptContent }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setSavedScript(scriptContent);
      })
      .catch((error) => console.error('Error saving script:', error));
  };

  return (
    <>
      <Helmet>
        {savedScript && <script>{savedScript}</script>}
      </Helmet>
      <Container>
        <Box my={4}>
          <Typography variant="h4" gutterBottom>
            Вставьте ваш скрипт в HEAD (аналітика і тд)
          </Typography>
          <TextField
            multiline
            rows={10}
            variant="outlined"
            value={scriptContent}
            onChange={handleScriptChange}
            placeholder="Введите ваш скрипт здесь (например, Google Analytics)"
            fullWidth
            margin="normal"
          />
          <Box mt={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSaveScript}
            >
              Сохранить скрипт
            </Button>
          </Box>
        </Box>
      </Container>
    </>
  );
}

export default ToCode;
