import "./Header.css";
import React, { useEffect } from 'react';
import { Link, useLocation } from "react-router-dom";
import logoIcon from "../../Assets/Icons/logoIcon.svg";
import logoText from "../../Assets/Icons/logoText.svg";
import geo from "../../Assets/Icons/geo.svg";
import Basket from "../Shops/Basket/Basket";
import MobManu from "./MobManu/MobManu";
import CatalogBtn from "./CatalogBtn/CatalogBtn";
import SearchInput from "./SearchInput/SearchInput";

function Header() {
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      const headerTop = document.querySelector('.headerTop');
      if (window.scrollY > 200 && window.innerWidth > 640) {
        headerTop.classList.add('active');
      } else {
        headerTop.classList.remove('active');
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <header>
        <div className="container">
          <div className="flex gap-[30px]">
            <div className="hidden sm:flex items-center ">
              <a href="/">
                <img src={logoIcon} alt="" />
              </a>
            </div>
            <div className="w-full">
              <div className="flex justify-between h-[60px] items-center">
                <div className="flex gap-3">
                  <a href="/" className="sm:hidden">
                    <img src={logoText} alt="" />
                  </a>
                  <div className="header__vertical-line"></div>
                  <a
                    href="https://maps.app.goo.gl/GARua4QNew8hM1fS9"
                    className="flex items-center gap-2"
                  >
                    <img src={geo} alt="" />
                    <p className="header__text">Київ</p>
                  </a>
                </div>
                <nav>
                  <ul className="hidden gap-[30px] lg:flex">
                    <li className={`header__text text__hover ${location.pathname === "/" ? "active" : ""}`}>
                      <Link to="/">Головна</Link>
                    </li>
                    <li className={`header__text text__hover ${location.pathname === "/shop" ? "active" : ""}`}>
                      <Link to="/shop">Магазин</Link>
                    </li>
                    <li className={`header__text text__hover ${location.pathname === "/search" ? "active" : ""}`}>
                      <Link to="/search">Каталог</Link>
                    </li>
                    <li className={`header__text text__hover ${location.pathname === "/about" ? "active" : ""}`}>
                      <Link to="/about">Про нас</Link>
                    </li>
                    <li className={`header__text text__hover ${location.pathname === "/contacts" ? "active" : ""}`}>
                      <Link to="/contacts">Контакти</Link>
                    </li>
                  </ul>
                  <MobManu />
                </nav>
              </div>
              <div className="line__horisont"></div>
              <div className="justify-between items-center py-[15px] gap-[8px] sm:gap-[30px] hidden sm:flex">
                <div className="BTN-CATALOG">
                  <CatalogBtn />
                </div>
                <SearchInput />
                <div className="header__info">
                  <a href="tel:+380631531173">+38(063)-153-11-73</a>
                  <div className="flex justify-between items-center mt-[6px]">
                    <p>Пн-Нд</p>
                    <p>9:00-21:00</p>
                  </div>
                </div>
                <Basket />
              </div>
            </div>
          </div>
        </div>
      </header>
      <section className="headerTop w-full bg-a-gray-0 top-0 z-10 py-[0px] sticky sm:fixed">
        <div className="container">
          <div className="flex justify-between items-center py-[15px] gap-[8px] sm:gap-[30px] head">
            <div className="BTN-CATALOG">
              <CatalogBtn />
            </div>
            <SearchInput />
            <div className="header__info">
              <a href="tel:+380631531173">+38(063)-153-11-73</a>
              <div className="flex justify-between items-center mt-[6px]">
                <p>Пн-Нд</p>
                <p>9:00-21:00</p>
              </div>
            </div>
            <Basket />
          </div>
        </div>
      </section>
    </>
  );
}

export default Header;
