import TitleSection from '../../All/Title/TitleSection'

import PopularArrow from '../../../Assets/Icons/popularArrow.svg'
import ReadySetsCard1 from "../../../Assets/Img/VisualCatalog/ReadySets/1.webp"
import ReadySetsCard2 from "../../../Assets/Img/VisualCatalog/ReadySets/2.webp"
import ReadySetsCard3 from "../../../Assets/Img/VisualCatalog/ReadySets/3.webp"
import ReadySetsCard4 from "../../../Assets/Img/VisualCatalog/ReadySets/4.webp"
import ReadySetsCard5 from "../../../Assets/Img/VisualCatalog/ReadySets/5.webp"
import ReadySetsCard6 from "../../../Assets/Img/VisualCatalog/ReadySets/6.webp"
import ReadySetsCard7 from "../../../Assets/Img/VisualCatalog/ReadySets/7.webp"
import ReadySetsCard8 from "../../../Assets/Img/VisualCatalog/ReadySets/8.webp"
import ReadySetsCard9 from "../../../Assets/Img/VisualCatalog/ReadySets/9.webp"
import ReadySetsCard10 from "../../../Assets/Img/VisualCatalog/ReadySets/10.webp"
import ReadySetsCard11 from "../../../Assets/Img/VisualCatalog/ReadySets/11.webp"
import ReadySetsCard12 from "../../../Assets/Img/VisualCatalog/ReadySets/12.webp"

function ReadySets() {
    return (
        <section className='PopularCategory'>
            <div className='container'>
                <TitleSection title="Готові набори" />
                <div className='PopularWrapper'>
                    <div className='PopularRow'>
                        <div className='PopularCol'>
                            <div className='PopularCard'>
                                <img src={ReadySetsCard1} alt='' className='PopularImg'/>
                                <div className='PopularContent'>
                                    <h3 className='text-white'>Ігри та соціальні</h3>
                                    <a href='/shop/Igri%20ta%20socialni'>
                                        <p>Детальніше</p>
                                        <img src={PopularArrow} alt='' className='PopularIcon'/>
                                    </a>
                                </div>
                            </div>
                            <div className='PopularCard'>
                                <img src={ReadySetsCard2} alt='' className='PopularImg'/>
                                <div className='PopularContent'>
                                    <h3 className=''>Виписка з пологового</h3>
                                    <a href='/shop/Vipiska%20z%20roddomu'>
                                        <p>Детальніше</p>
                                        <img src={PopularArrow} alt='' className='PopularIcon'/>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className='PopularCol'>
                        <div className='PopularCard'>
                                <img src={ReadySetsCard3} alt='' className='PopularImg'/>
                                <div className='PopularContent'>
                                    <h3 className=''>На перше вересня</h3>
                                    <a href='/shop/1%20veresnya'>
                                        <p>Детальніше</p>
                                        <img src={PopularArrow} alt='' className='PopularIcon'/>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='PopularRow'>
                        <div className='PopularCol'>
                            <div className='PopularCard'>
                                <img src={ReadySetsCard4} alt='' className='PopularImg'/>
                                <div className='PopularContent'>
                                    <h3 className='text-white'>На дівич вечір</h3>
                                    <a href='/shop/Divich%2Fparubocka%20vechirka'>
                                        <p>Детальніше</p>
                                        <img src={PopularArrow} alt='' className='PopularIcon'/>
                                    </a>
                                </div>
                            </div>
                            <div className='PopularCard'>
                                <img src={ReadySetsCard5} alt='' className='PopularImg'/>
                                <div className='PopularContent'>
                                    <h3 className=''>День народження</h3>
                                    <a href='/shop/Den%20narodzhennya'>
                                        <p>Детальніше</p>
                                        <img src={PopularArrow} alt='' className='PopularIcon'/>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className='PopularCol'>
                        <div className='PopularCard'>
                                <img src={ReadySetsCard6} alt='' className='PopularImg'/>
                                <div className='PopularContent'>
                                    <h3 className=''>Гендерпаті</h3>
                                    <a href='/shop/Genderpati'>
                                        <p>Детальніше</p>
                                        <img src={PopularArrow} alt='' className='PopularIcon'/>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='PopularWrapper mt-[14px] sm:mt-[20px]'>
                    <div className='PopularRow'>
                        <div className='PopularCol'>
                            <div className='PopularCard'>
                                <img src={ReadySetsCard7} alt='' className='PopularImg'/>
                                <div className='PopularContent'>
                                    <h3 className=''>День Закоханих</h3>
                                    <a href='/shop/14%20lyutogo'>
                                        <p>Детальніше</p>
                                        <img src={PopularArrow} alt='' className='PopularIcon'/>
                                    </a>
                                </div>
                            </div>
                            <div className='PopularCard'>
                                <img src={ReadySetsCard8} alt='' className='PopularImg'/>
                                <div className='PopularContent'>
                                    <h3 className='text-white'>Хелоувин</h3>
                                    <a href='/shop/Helouvin'>
                                        <p>Детальніше</p>
                                        <img src={PopularArrow} alt='' className='PopularIcon'/>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className='PopularCol'>
                        <div className='PopularCard'>
                                <img src={ReadySetsCard9} alt='' className='PopularImg'/>
                                <div className='PopularContent'>
                                    <h3 className='text-white'>На 1-й день народження</h3>
                                    <a href='/shop/Na%20pershiy%20den%20narodzhennya'>
                                        <p>Детальніше</p>
                                        <img src={PopularArrow} alt='' className='PopularIcon'/>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='PopularRow'>
                        <div className='PopularCol'>
                            <div className='PopularCard'>
                                <img src={ReadySetsCard10} alt='' className='PopularImg'/>
                                <div className='PopularContent'>
                                    <h3 className=''>8 Березня</h3>
                                    <a href='/shop/8%20bereznya'>
                                        <p>Детальніше</p>
                                        <img src={PopularArrow} alt='' className='PopularIcon'/>
                                    </a>
                                </div>
                            </div>
                            <div className='PopularCard'>
                                <img src={ReadySetsCard11} alt='' className='PopularImg'/>
                                <div className='PopularContent'>
                                    <h3 className='text-white'>Новий Рік</h3>
                                    <a href='/shop/Noviy%20rik'>
                                        <p>Детальніше</p>
                                        <img src={PopularArrow} alt='' className='PopularIcon'/>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className='PopularCol'>
                        <div className='PopularCard'>
                                <img src={ReadySetsCard12} alt='' className='PopularImg'/>
                                <div className='PopularContent'>
                                    <h3 className='text-white'>Парубоцька вечірка</h3>
                                    <a href='/shop/Divich%2Fparubocka%20vechirka'>
                                        <p>Детальніше</p>
                                        <img src={PopularArrow} alt='' className='PopularIcon'/>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
  
export default ReadySets