// src/App.js
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useLocation,
} from "react-router-dom";
import { BasketProvider } from "./context/BasketContext"; // Импортируем BasketProvider
import { PageProvider } from "./context/PageContext"; // Импортируем BasketProvider

// pages
import Home from "./Pages/Home/Home.js";
import Catalog from "./Pages/Catalog/Catalog.js";
import Admin from "./Pages/Admin/Admin.js";

// components
import Header from "./Components/Header/Header.js";
import Footer from "./Components/Footer/Footer.js";
import ProductDetail from "./Pages/ProductDetail/ProductDetail.js";
import NotFound from "./Pages/404/NotFound.js";
import Discounted from "./Pages/Admin/pages/Discounted.js";
import Category from "./Pages/Admin/pages/Category.js";
import CategoryList from "./Pages/Admin/pages/CategoryList.js";
import HeroSliderList from "./Pages/Admin/pages/HeroSliderLis.js";
import Delivery from "./Pages/Delivery/Delivery.js";
import Contacts from "./Pages/Contacts/Contacts.js";
import ColorList from "./Pages/Admin/pages/ColorList.js";
import Product from "./Pages/Admin/pages/Product/index.js";
import ShopProductList from "./Pages/Admin/pages/ShopProductList.js";
import Login from "./Pages/Admin/pages/Login";
import Checkout from "./Pages/Checkout/Checkout.js";
import PrivatPolicy from "./Pages/PrivatPolicy/PrivatPolicy.js";
import CategoryDetails from "./Pages/Admin/pages/Category/CategoryDetails.js";
import Request from "./Pages/Admin/pages/Request/Request.js";
import RequestDetails from "./Pages/Admin/pages/RequestDetails/index.js";
import ShopCard from "./Components/Shops/Shop/ShopCard/ShopCard.js";
import Search from "./Pages/Search/Search.js";
import About from "./Pages/About/About.js";
import Thanks from "./Pages/Thanks/Thanks.js";
import ToCode from "./Pages/Admin/pages/ToCode/ToCode.js";
import GlobalScripts from "./Components/GlobalScripts/GlobalScripts.js";

function App() {
  const location = useLocation();

  // Hide header, footer on /admin
  const isAdminPage = location.pathname.startsWith("/admin");
  const isLoginPage = location.pathname.startsWith("/login");

  return (
    <BasketProvider>
      <PageProvider>
        <GlobalScripts />
        {!isAdminPage && !isLoginPage && <Header />}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/shop" element={<Catalog />} />
          <Route path="/delivery" element={<Delivery />} />
          <Route path="/search" element={<Search />} />
          <Route path="/about" element={<About />} />
          <Route path="/contacts" element={<Contacts />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/privat-policy" element={<PrivatPolicy />} />
          <Route path="/thanks" element={<Thanks />} />

          <Route path="/catalog/:productTitle" element={<ProductDetail />} />
          <Route path="/shop/:subcategory" element={<Catalog />} />

          <Route path="/admin/*" element={<Admin />}>
            <Route path="discounted" element={<Discounted />} />
            <Route path="Category" element={<Category />} />
            <Route path="colorlist" element={<ColorList />} />
            <Route path="to-code" element={<ToCode />} />
            
            
            <Route path="categorylist" element={<CategoryList />} />
            <Route path="category/:categoryId" element={<CategoryDetails />} />
            <Route path="herosliderlist" element={<HeroSliderList />} />
            <Route path="requests" element={<Request />} />
            <Route path="requests/:requestId" element={<RequestDetails />} />
            <Route path="shopproductlist" element={<ShopProductList />} />
            <Route path="product/:productId" element={<Product />} />
            
          </Route>

          <Route path="/login" element={<Login />} />

          <Route path="*" element={<NotFound />} />
        </Routes>
        {!isAdminPage && !isLoginPage && <Footer />}
      </PageProvider>
    </BasketProvider>
  );
}

export default App;
