import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { useBasket } from "../../../../context/BasketContext";
import CardBallon from "./CardBallon/CardBallon";
import CustomPagination from "../CustomPagination/CustomPagination";
import BasketImg from "../../../../Assets/Icons/basket.svg";
import "./ShopCard.css";
import { Alert, Snackbar } from "@mui/material";
import { transliterate } from 'transliteration';
import defoltFoto from '../../../../Assets/Img/Shop/defoltFoto.png';
import { usePriceFilter } from '../../../../context/PriceFilterContext';
import { PageContext } from "../../../../context/PageContext";

const apiUrl = "https://backend.balloonflower.kyiv.ua";
const token = localStorage.getItem("auth_token");

function ShopCard() {
    const { subcategory } = useParams();
    const [ProductData, setProduct] = useState([]);
    const [activeColorIndexes, setActiveColorIndexes] = useState([]);
    const { addToBasket } = useBasket();
    const [open, setOpen] = useState(false);
    const [noColorsOpen, setNoColorsOpen] = useState(false); // Новое состояние для уведомления об отсутствии цветов
    const { priceRange, sortOrder, selectedColors } = usePriceFilter();
    
    // Используем контекст для управления текущей страницей
    const { currentPage, setCurrentPage, resetPage } = useContext(PageContext);

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await fetch(`${apiUrl}/api/products`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                const data = await response.json();

                const productsWithColors = data.products.map(product => {
                    const colors = data.product_colors.filter(color => color.product_id === product.id);
                    return { ...product, colors };
                });

                setProduct(productsWithColors);
                setActiveColorIndexes(productsWithColors.map(() => 0));
            } catch (error) {
                console.error("Error fetching products:", error);
            }
        };

        fetchProducts();
    }, []);

    const filteredProducts = ProductData.filter(product => {
        const isInPriceRange = product.price >= priceRange[0] && product.price <= priceRange[1];
        const isInSubcategory = subcategory ? transliterate(product.category) === subcategory : true;
        const hasSelectedColor = selectedColors.length === 0 || product.colors.some(color => selectedColors.includes(color.color));
        return isInPriceRange && isInSubcategory && hasSelectedColor;
    });

    const sortedProducts = filteredProducts.sort((a, b) => {
        if (sortOrder === 'asc') {
            return a.price - b.price;
        } else if (sortOrder === 'desc') {
            return b.price - a.price;
        } else {
            return 0;
        }
    });

    const handleCardBallonClick = (clickedColorIndex, cardIndex) => {
        setActiveColorIndexes((prevIndexes) => prevIndexes.map((index, i) => (i === cardIndex ? clickedColorIndex : index)));

        const updatedProductData = [...ProductData];
        updatedProductData[cardIndex].photoColor = ProductData[cardIndex].colors[clickedColorIndex].photo;
        setProduct(updatedProductData);
    };

    const handlePageChange = (event, page) => {
        setCurrentPage(page);
    };

    const handleAddToBasket = (productItem, activeColorIndex) => {
        if (productItem.colors.length === 0) {
            setNoColorsOpen(true); // Показать уведомление об отсутствии цветов
        } else {
            const productWithSelectedColor = {
                ...productItem,
                selectedColor: productItem.colors[activeColorIndex],
                quantity: 1
            };
            addToBasket(productWithSelectedColor);
            setOpen(true);
        }
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
        setNoColorsOpen(false); // Закрыть уведомление об отсутствии цветов
    };

    const startIndex = (currentPage - 1) * 12;
    const endIndex = startIndex + 12;

    return (
        <>
            <div className="Cards">
                {sortedProducts.slice(startIndex, endIndex).map((productItem, cardIndex) => {
                    const transliteratedTitle = transliterate(productItem.title);
                    const uniqueColors = Array.from(new Set(productItem.colors.map(color => color.color)))
                        .map(color => {
                            return productItem.colors.find(c => c.color === color);
                        });

                    return (
                        <div key={cardIndex} className="ShopCard">
                            <div className="CardPrices">
                                <p className="CardPrice PriceOld">
                                    {productItem.price_old !== "" && `${productItem.price_old} грн`}
                                </p>
                                <p className="CardPrice PriceCurrent">{productItem.price} грн</p>
                            </div>
                            <img className="cardTitleImg"
                                src={
                                    productItem.colors[activeColorIndexes[cardIndex]]?.photo ? `${apiUrl}/uploads/${productItem.colors[activeColorIndexes[cardIndex]].photo}` : defoltFoto}
                                alt=""
                            />
                            <div className="CardInfo">
                                <p className="CardTitle">{productItem.title}</p>
                                <div className="CardColors">
                                    {uniqueColors.map((color, indexColor) => (
                                        <div
                                            key={indexColor}
                                            onClick={() => handleCardBallonClick(productItem.colors.findIndex(c => c.color === color.color), cardIndex)}
                                        >
                                            <CardBallon
                                                color={color.color}
                                                isActive={productItem.colors.findIndex(c => c.color === color.color) === activeColorIndexes[cardIndex]}
                                            />
                                        </div>
                                    ))}
                                </div>
                                <div className="CardBottom">
                                    {/* <Link className="CardBtn" to={`/catalog/${encodeURIComponent(transliteratedTitle)}`}>Детальніше</Link> */}
                                    <a className="CardBtn" href={`/catalog/${encodeURIComponent(transliteratedTitle)}`}>Детальніше</a>
                                    <div className="CardBasket" onClick={() => handleAddToBasket(productItem, activeColorIndexes[cardIndex])}>
                                        <img src={BasketImg} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
            <CustomPagination onPageChange={handlePageChange} pageCount={Math.ceil(sortedProducts.length / 12)} currentPage={currentPage}  />
            <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
                <Alert
                    onClose={handleClose}
                    severity="success"
                    variant="filled"
                    sx={{ width: '100%' }}>
                    Товар додано до кошика
                </Alert>
            </Snackbar>

            <Snackbar open={noColorsOpen} autoHideDuration={2000} onClose={handleClose}>
                <Alert
                    onClose={handleClose}
                    severity="error"
                    variant="filled"
                    sx={{ width: '100%' }}>
                    Даного товару немає в наявності
                </Alert>
            </Snackbar>
        </>
    );
}

export default ShopCard;
