import React, { useState, useEffect} from 'react';

import './HeroSlider.css'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';

import HomeHeroSlide1 from "../../../Assets/Img/Home/Hero/Sliders/1.webp"
import { Link } from 'react-router-dom';

function HeroSlider() {
    const [homeHeroData, setHomeHeroData] = useState([]);

    useEffect(() => {
        fetch('https://backend.balloonflower.kyiv.ua/api/slider')
            .then(response => response.json())
            .then(data => setHomeHeroData(data))
            .catch(error => console.error('Error fetching data:', error));
    }, []);
  return (
  <>
    <div className='HeroSlider'>
    <Swiper
      modules={[Pagination, Autoplay]}
      spaceBetween={20}
      slidesPerView={1}
      pagination={{ clickable: true }}
      autoplay={{ delay: 10000 }}
    >
        <SwiperSlide>
            <div  className='heroSlide'>
                <img src={HomeHeroSlide1} alt='' />
                <div className='heroSlide-content'>
                    <h1>Знижки до 50% Надувні Кульки та Сети з Кульками!</h1>
                    <ul>
                        <li><p>Купуйте 2 Набори Кульок та Отримайте 1 в Подарунок!</p></li>
                        <li><p>Все для Незабутніх Моментів!</p></li>
                        <li><p>Купуйте 2 Набори Кульок та Отримайте 1 в Подарунок!</p></li>
                    </ul>
                    <p>Надувні кульки для незабутнього дівич-вечора!</p>
                    <Link className='button white' to="/shop">Перейти в каталог</Link>
                </div>
            </div>
        </SwiperSlide>
        {homeHeroData.map((homeHeroItem, index) => (
            <SwiperSlide key={index}>
                <div  className='heroSlide'>
                    <img src={`https://backend.balloonflower.kyiv.ua/uploads/${homeHeroItem.photo}`} alt='' />
                    <div className='heroSlide-content'>
                        <h2>{homeHeroItem.title}</h2>
                        <p>{homeHeroItem.description}</p>
                    </div>
                </div>
            </SwiperSlide>
        ))}
    </Swiper>
    </div>
    
  </>
  )
}

export default HeroSlider