import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';

const apiUrl = "https://backend.balloonflower.kyiv.ua";
const token = localStorage.getItem("auth_token");

function GlobalScripts() {
  const [savedScript, setSavedScript] = useState('');

  useEffect(() => {
    // Загружаем скрипт с сервера при загрузке компонента
    fetch(`${apiUrl}/api/get-script`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setSavedScript(data.scriptContent);
      })
      .catch((error) => console.error('Error fetching script:', error));
  }, []);

  return (
    <Helmet>
      {savedScript && <script>{savedScript}</script>}
    </Helmet>
  );
}

export default GlobalScripts;