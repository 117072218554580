import './EmptyBasket.css'
import LogoFull from '../../../../Assets/Icons/logoFull.svg'
import { Link } from 'react-router-dom'

function EmptyBasket({ handleClose }) {

  return (
  <div className='EmptyBasket'>
    <img src={LogoFull} alt='' />
    <p>Кошик порожній</p>
    <a href='/shop' className='button' onClick={handleClose}>За покупками</a>
  </div>
  )
}

export default EmptyBasket