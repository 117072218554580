import Contact from '../../Components/All/Contact/Contact'
import DeliveryOffer from '../../Components/All/DeliveryOffer/DeliveryOffer'
import Offer from '../../Components/All/Offer/Offer'
import PopularCategory from '../../Components/All/PopularCategory/PopularCategory'
import HomeHero from '../../Components/Home/HomeHero'
import Promo from '../../Components/Home/Promo/Promo'
import Shop from '../../Components/Shops/Shop/Shop'
import './Home.css'


function Home() {

  return (
  <>
    <HomeHero />
    <Promo />
    <Shop />
    <Offer />
    <PopularCategory />
    <DeliveryOffer />
    <Contact />
  </>
  )
}

export default Home
