// BasketContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';

const BasketContext = createContext();

export const useBasket = () => {
  return useContext(BasketContext);
};

const saveBasketToLocalStorage = (basket) => {
  localStorage.setItem('basket', JSON.stringify(basket));
};

const loadBasketFromLocalStorage = () => {
  const basket = localStorage.getItem('basket');
  return basket ? JSON.parse(basket) : [];
};

export const BasketProvider = ({ children }) => {
  const [basket, setBasket] = useState(loadBasketFromLocalStorage());

  useEffect(() => {
    saveBasketToLocalStorage(basket);
  }, [basket]);

  const addToBasket = (item) => {
    setBasket(prevBasket => {
        const existingItemIndex = prevBasket.findIndex(
            (basketItem) => basketItem.title === item.title && basketItem.selectedColor.color === item.selectedColor.color
        );

        if (existingItemIndex >= 0) {
            return prevBasket.map((basketItem, index) =>
                index === existingItemIndex
                    ? { ...basketItem, quantity: basketItem.quantity + item.quantity }
                    : basketItem
            );
        } else {
            return [...prevBasket, item];
        }
    });
  };

  const removeFromBasket = (index) => {
    setBasket(basket.filter((_, i) => i !== index));
  };

  const updateQuantity = (index, quantity) => {
    const newBasket = basket.map((item, i) => 
      i === index ? { ...item, quantity } : item
    );
    setBasket(newBasket);
  };

  // на будущее для очищения корзины при успешном оформлении заказа
  const clearBasket = () => {
    setBasket([]);
    localStorage.removeItem('basket');
  };

  return (
    <BasketContext.Provider value={{ basket, addToBasket, removeFromBasket, updateQuantity, clearBasket }}>
      {children}
    </BasketContext.Provider>
  );
};
