import React from 'react';
import { Breadcrumbs, Typography } from '@mui/material';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import './CheckoutBreadcrmbs.css';
import { Link } from 'react-router-dom';

function CheckoutBreadcrmbs() {
  return (
    <div role="presentation" className='CheckoutBreadcrmbs'>
      <Breadcrumbs aria-label="breadcrumb" separator="›">
        <Link underline="hover" color="inherit" to="/">
          <HomeOutlinedIcon fontSize="inherit" />
        </Link>
        <Link
          underline="hover"
          color="inherit"
          to="/shop/"
        >
          Каталог
        </Link>
        <Link
          color="inherit"
        >
          Оформлення замовлення
        </Link>
      </Breadcrumbs>
    </div>
  );
}

export default CheckoutBreadcrmbs;