import TitleSection from '../../All/Title/TitleSection'

import PopularArrow from '../../../Assets/Icons/popularArrow.svg'
import ForWhomCard1 from "../../../Assets/Img/VisualCatalog/ForWhom/1.webp"
import ForWhomCard2 from "../../../Assets/Img/VisualCatalog/ForWhom/2.webp"
import ForWhomCard3 from "../../../Assets/Img/VisualCatalog/ForWhom/3.webp"
import ForWhomCard4 from "../../../Assets/Img/VisualCatalog/ForWhom/4.webp"


function ForWhom() {
    return (
        <section className='PopularCategory'>
            <div className='container'>
                <TitleSection title="Для Кого" />
                <div className='PopularWrapper'>
                    <div className='PopularRow'>
                        <div className='PopularCol'>
                            <div className='PopularCard'>
                                <img src={ForWhomCard1} alt='' className='PopularImg'/>
                                <div className='PopularContent'>
                                    <h3 className='text-white'>Для неї</h3>
                                    <a href='/shop/Dlya%20neyi'>
                                        <p>Детальніше</p>
                                        <img src={PopularArrow} alt='' className='PopularIcon'/>
                                    </a>
                                </div>
                            </div>
                            <div className='PopularCard'>
                                <img src={ForWhomCard2} alt='' className='PopularImg'/>
                                <div className='PopularContent'>
                                    <h3 className=''>Для нього</h3>
                                    <a href='/shop/Dlya%20nogo'>
                                        <p>Детальніше</p>
                                        <img src={PopularArrow} alt='' className='PopularIcon'/>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className='PopularCol'>
                        <div className='PopularCard'>
                                <img src={ForWhomCard3} alt='' className='PopularImg'/>
                                <div className='PopularContent'>
                                    <h3 className=''>Для дітей</h3>
                                    <a href='/shop/Dlya%20ditey'>
                                        <p>Детальніше</p>
                                        <img src={PopularArrow} alt='' className='PopularIcon'/>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='PopularRow'>
                        <div className='PopularCol'>
                            <div className='PopularCard'>
                                <img src={ForWhomCard4} alt='' className='PopularImg'/>
                                <div className='PopularContent'>
                                    <h3 className=''>Для батьків</h3>
                                    <a href='/shop/Dlya%20batkiv'>
                                        <p>Детальніше</p>
                                        <img src={PopularArrow} alt='' className='PopularIcon'/>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
  
export default ForWhom