import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import "./AddProduct.css";

function AddProduct({
  initialData = {},
  items,
  onSave,
  buttonText = "Edit",
  discount_id,
}) {
  const [open, setOpen] = useState(false);
  const [edited, setEdited] = useState(false);
  const [products, setProducts] = useState([]);
  const [formData, setFormData] = useState({
    id: initialData.id || "",
    product_id: initialData.category || "",
    discount: initialData.discount || "",
    photo: initialData.photo || "",
    title: initialData.title || "",
  });

  const [file, setFile] = useState(null);
  const handleFileChange = (event) => {
    setEdited(true);
    setFile(event.target.files[0]);
  };

  useEffect(() => {
    if (open) {
      setFormData(initialData);
    }
    fetch("https://backend.balloonflower.kyiv.ua/api/products")
      .then((res) => res.json())
      .then((data) => setProducts(data.products));

    if (discount_id) {
      setFile(initialData.photo || null);
    }
  }, [open, initialData, discount_id]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setFile(null);
    setEdited(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSave = () => {
    const requestInfo = new FormData();

    requestInfo.append("product_id", formData.product_id);
    requestInfo.append("discount", formData.discount);
    requestInfo.append("photo", file);
    requestInfo.append("title", formData.title);
    if (discount_id) {
      requestInfo.append("id", formData.id);
    }
    onSave(requestInfo);
    handleClose();
  };

  return (
    <div>
      <Button
        sx={{ marginLeft: "4px", marginTop: "4px" }}
        variant="outlined"
        onClick={handleClickOpen}
      >
        {buttonText}
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{buttonText}</DialogTitle>
        <DialogContent>
          <FormControl fullWidth margin="dense">
            <InputLabel>Продукт</InputLabel>
            <Select
              name="product_id"
              value={formData.product_id}
              onChange={handleChange}
              label="Продукт"
            >
              {products.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            margin="dense"
            name="discount"
            label="Скидка"
            type="text"
            fullWidth
            value={formData.discount}
            onChange={handleChange}
            required={true}
          />

          {/* <input type="file" onChange={handleFileUpload} /> */}
          <TextField
            margin="dense"
            name="title"
            label="Титул"
            type="text"
            fullWidth
            value={formData.title}
            onChange={handleChange}
            required={true}
          />
          {file && (!discount_id || edited) ? (
            <img
              className="w-full h-40 object-contain"
              src={URL.createObjectURL(file)}
              alt="preview"
            />
          ) : file ? (
            <img
              className="w-full h-40 object-contain"
              src={`https://backend.balloonflower.kyiv.ua/uploads/${file}`}
              alt="preview"
            />
          ) : null}
          <Button
            component="label"
            variant="contained"
            fullWidth
            startIcon={<CloudUploadIcon />}
          >
            Загрузить изображение
            <input
              onChange={handleFileChange}
              name="photo"
              className="hidden"
              type="file"
            />
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSave}>Save</Button>
        </DialogActions>
      </Dialog>

      {/* <ConfirmDialog
        open={confirmDialogOpen}
        onClose={closeConfirmDialog}
        onConfirm={confirmDeleteImage}
        title="Confirm Delete"
        content="Are you sure you want to delete this image?"
      /> */}
    </div>
  );
}

export default AddProduct;
