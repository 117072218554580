import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { ChromePicker } from "react-color";

function AddColor({ initialData = {}, onSave, buttonText = "Edit", titleText = "Edit" }) {
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    id: initialData.id || "",
    color: initialData.color || "",
  });
  const [formErrors, setFormErrors] = useState({
    color: false,
  });

  useEffect(() => {
    if (open) {
      setFormData(initialData);
    }
  }, [open, initialData]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (color) => {
    setFormData({ ...formData, color: color.hex });
  };

  const handleSave = () => {
    const errors = {};
    let hasErrors = false;
    if (!formData.color) {
      errors.color = true;
      hasErrors = true;
    } else {
      errors.color = false;
    }
    setFormErrors(errors);

    if (!hasErrors) {
      onSave(formData);
      handleClose();
    }
  };

  return (
    <div>
      <Button
        sx={{ marginLeft: "4px", marginTop: "4px" }}
        variant="outlined"
        onClick={handleClickOpen}
      >
        {buttonText}
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{titleText}</DialogTitle>
        <DialogContent>
          <ChromePicker
            color={formData.color}
            onChangeComplete={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSave}>Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AddColor;
