import "./Admin.css";
import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import {
  Box,
  Drawer as MuiDrawer,
  AppBar as MuiAppBar,
  Toolbar,
  List,
  CssBaseline,
  Typography,
  Divider,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import {
  Menu as MenuIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  MoveToInbox as InboxIcon,
  Mail as MailIcon,
} from "@mui/icons-material";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import {
  Routes,
  Route,
  useNavigate,
  Link,
  useLocation,
} from "react-router-dom";
import PercentOutlinedIcon from "@mui/icons-material/PercentOutlined";
import FormatColorFillIcon from "@mui/icons-material/FormatColorFill";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

import Discounted from "./pages/Discounted";
import Category from "./pages/Category";
import CategoryList from "./pages/CategoryList";
import HeroSliderList from "./pages/HeroSliderLis";
import ColorList from "./pages/ColorList";
import ShopProductList from "./pages/ShopProductList";
import checkAuth from "../../utils/auth";
import Product from "./pages/Product/Product";
import CategoryDetails from "./pages/Category/CategoryDetails";
import Request from "./pages/Request";
import RequestDetails from "./pages/RequestDetails";
import ToCode from "./pages/ToCode/ToCode";
import CodeOutlinedIcon from '@mui/icons-material/CodeOutlined';

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const menuItems = [
  { text: "Акції", icon: <PercentOutlinedIcon />, path: "discounted" },
  { text: "Слайдер", icon: <MailIcon />, path: "herosliderlist" },
  { text: "Категорії", icon: <CategoryOutlinedIcon />, path: "categorylist" },
  { text: "Кольори", icon: <FormatColorFillIcon />, path: "colorlist" },
  { text: "Продукти", icon: <Inventory2Icon />, path: "shopproductlist" },
  { text: "Заказы", icon: <ShoppingCartIcon />, path: "requests" },
  // { text: "Доп Код", icon: <CodeOutlinedIcon />, path: "to-code" },
];

function Admin() {
  const theme = useTheme();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [currentPath, setCurrentPath] = React.useState(
    window.location.pathname
  );

  const location = useLocation();
  const isAdminPage = location.pathname.startsWith("/admin");

  if (isAdminPage) {
    checkAuth();
  }

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleMenuItemClick = (path) => {
    setCurrentPath(`/admin/${path}`);
    navigate(`/admin/${path}`);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ marginRight: 5, ...(open && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            Ballons
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Typography
            component={Link}
            to="/"
            sx={{ color: "inherit", textDecoration: "none" }}
          >
            До магазину
          </Typography>
        </Toolbar>
      </AppBar>

      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {menuItems.map((item) => (
            <ListItem
              key={item.text}
              disablePadding
              sx={{ display: "block" }}
              className={currentPath === `/admin/${item.path}` ? "active" : ""}
              onClick={() => handleMenuItemClick(item.path)}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText
                  primary={item.text}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>

      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        <Routes>
          <Route path="discounted" element={<Discounted />} />
          <Route path="categorylist" element={<CategoryList />} />
          <Route path="category/:categoryId" element={<CategoryDetails />} />
          <Route path="herosliderlist" element={<HeroSliderList />} />
          <Route path="colorlist" element={<ColorList />} />
          <Route path="to-code" element={<ToCode />} />
          <Route path="shopproductlist" element={<ShopProductList />} />
          <Route path="product/:productId" element={<Product />} />
          <Route path="сategory" element={<Category />} />
          <Route path="requests" element={<Request />} />
          <Route path="requests/:requestId" element={<RequestDetails />} />

        </Routes>
      </Box>
    </Box>
  );
}

export default Admin;
