
// TelegramSender.js
import React from 'react';

const TELEGRAM_BOT_TOKEN = '7429637074:AAEVC6OGlu7_KFYwBT4ZSuuJvl-DQfI-9Hc';
const TELEGRAM_CHAT_ID = '-1002239636487';

export const sendToTelegram = async (message) => {
  const url = `https://api.telegram.org/bot${TELEGRAM_BOT_TOKEN}/sendMessage`;
  const data = {
    chat_id: TELEGRAM_CHAT_ID,
    text: message,
  };

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error sending message to Telegram:', error);
    throw error;
  }
};