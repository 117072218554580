// import React, { useState, useEffect } from "react";
// import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from "@mui/material";
// import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
// import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
// import AddProduct from "../../../Components/Admin/AddProduct/AddProduct";

// function Discounted() {
//   const [promoData, setPromoData] = useState([]);

//   useEffect(() => {
//     fetch('https://backend.balloonflower.kyiv.ua/api/discounted-cards')
//       .then(response => response.json())
//       .then(data => setPromoData(data))
//       .catch(error => console.error('Error fetching data:', error));
//   }, []);

//   const handleEdit = (id) => {
//     // Ваша логика редактирования
//     console.log("Edit item with id:", id);
//   };

//   const handleDelete = (id) => {
//     fetch(`/api/discounted-cards/${id}`, {
//       method: 'DELETE'
//     })
//       .then(() => {
//         setPromoData(promoData.filter(item => item.id !== id));
//       })
//       .catch(error => console.error('Error deleting data:', error));
//   };

//   return (
//     <section>
//       <div className="container ">
//         <div className="flex flex-col gap-[40px]">
//           <div className="HEAD flex justify-between">
//             <div className="">
//               <h2>Акційні товари</h2>
//               <p>Слайдер з товарами який відображається на головній сторінці</p>
//             </div>
//             <div>
//               <div className="button">Добавити картку</div>
//             </div>
//           </div>

//           <div>
//             <TableContainer component={Paper}>
//               <Table aria-label="discounts table">
//                 <TableHead>
//                   <TableRow>
//                     <TableCell>Category</TableCell>
//                     <TableCell>Photo</TableCell>
//                     <TableCell>Discount</TableCell>
//                     <TableCell>Title</TableCell>
//                     <TableCell>Actions</TableCell>
//                   </TableRow>
//                 </TableHead>
//                 <TableBody>
//                   {promoData.map((item, index) => (
//                     <TableRow key={item.id}>
//                       <TableCell>{item.category}</TableCell>
//                       <TableCell>
//                         <img className="w-[60px]" src={item.photo} />
//                       </TableCell>
//                       <TableCell>{item.discount}</TableCell>
//                       <TableCell>{item.title}</TableCell>
//                       <TableCell >
//                         <AddProduct />
//                         <Button
//                         sx={{ marginLeft: '4px', marginTop: '4px' }}
//                           variant="outlined"
//                           onClick={() => handleDelete(item.id)}
//                         >
//                           <DeleteOutlineOutlinedIcon />
//                         </Button>
//                       </TableCell>
//                     </TableRow>
//                   ))}
//                 </TableBody>
//               </Table>
//             </TableContainer>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// }

// export default Discounted;

import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import AddProduct from "../../../Components/Admin/AddProduct/AddProduct";
import ConfirmDialog from "../../../Components/Admin/ConfirmDialog/ConfirmDialog";

function Discounted() {
  const [promoData, setPromoData] = useState([]);
  const [items, setItems] = useState([]);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [addProductOpen, setAddProductOpen] = useState(false);

  useEffect(() => {
    fetch("https://backend.balloonflower.kyiv.ua/api/discount")
      .then((response) => response.json())
      .then((data) => setPromoData(data))
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const handleDelete = (id) => {
    fetch(`https://backend.balloonflower.kyiv.ua/api/discount/${id}`, {
      method: "DELETE",
      headers: {
        token: localStorage.getItem("auth_token"),
      },
    })
      .then(() => {
        setPromoData(promoData.filter((item) => item.id !== id));
        setConfirmDialogOpen(false);
        setDeleteId(null);
      })
      .catch((error) => console.error("Error deleting data:", error));
  };

  const handleSave = (updatedData) => {
    fetch(`https://backend.balloonflower.kyiv.ua/api/discount`, {
      method: "PUT",
      headers: {
        token: localStorage.getItem("auth_token"),
      },
      body: updatedData,
    })
      .then((response) => response.json())
      .then(() => {
        fetch("https://backend.balloonflower.kyiv.ua/api/discount")
          .then((response) => response.json())
          .then((data) => setPromoData(data))
          .catch((error) => console.error("Error fetching data:", error));
      })
      .catch((error) => console.error("Error updating data:", error));
  };

  const handleAdd = (newData) => {
    fetch("https://backend.balloonflower.kyiv.ua/api/discount", {
      method: "POST",
      headers: {
        token: localStorage.getItem("auth_token"),
      },
      body: newData,
    })
      .then((response) => response.json())
      .then((newItem) => {
        setPromoData([...promoData, newItem]);
        setAddProductOpen(false);
      })
      .catch((error) => console.error("Error adding data:", error));
  };

  const openConfirmDialog = (id) => {
    setDeleteId(id);
    setConfirmDialogOpen(true);
  };

  const closeConfirmDialog = () => {
    setConfirmDialogOpen(false);
    setDeleteId(null);
  };

  const confirmDelete = () => {
    if (deleteId) {
      handleDelete(deleteId);
    }
  };

  return (
    <section>
      <div className="container">
        <div className="flex flex-col gap-[40px]">
          <div className="HEAD flex justify-between">
            <div>
              <h2>Акційні товари</h2>
              <p>Слайдер з товарами який відображається на головній сторінці</p>
            </div>
            <div>
              <AddProduct
                open={addProductOpen}
                onClose={() => setAddProductOpen(false)}
                onSave={handleAdd}
                items={items}
                initialData={{
                  product_id: "",
                  discount: "",
                  photo: "",
                  title: "",
                }}
                buttonText="Добавити нову картку"
              />
            </div>
          </div>

          <div>
            <TableContainer component={Paper}>
              <Table aria-label="discounts table">
                <TableHead>
                  <TableRow>
                    <TableCell>Product</TableCell>
                    <TableCell>Photo</TableCell>
                    <TableCell>Discount</TableCell>
                    <TableCell>Title</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {promoData.map((item) => (
                    <TableRow key={item.id}>
                      <TableCell>{item.product}</TableCell>
                      <TableCell>
                        <img
                          className="w-[60px]"
                          src={`https://backend.balloonflower.kyiv.ua/uploads/${item.photo}`}
                          alt={item.title}
                        />
                      </TableCell>
                      <TableCell>{item.discount}</TableCell>
                      <TableCell>{item.title}</TableCell>
                      <TableCell>
                        <AddProduct
                          initialData={item}
                          onSave={handleSave}
                          items={items}
                          discount_id={item.id}
                        />
                        <Button
                          sx={{ marginLeft: "4px", marginTop: "4px" }}
                          variant="outlined"
                          onClick={() => openConfirmDialog(item.id)}
                        >
                          <DeleteOutlineOutlinedIcon />
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>

      <ConfirmDialog
        open={confirmDialogOpen}
        onClose={closeConfirmDialog}
        onConfirm={confirmDelete}
        title="Confirm Delete"
        content="Are you sure you want to delete this item?"
      />
    </section>
  );
}

export default Discounted;
