
import './TitleSection.css'


function TitleSection({title}) {

  return (
  <>
    <h2 className='pb-[36px] md:pb-[40px]'>{title}</h2>
  </>
  )
}

export default TitleSection