import React, { useState, useEffect } from "react";
import { useParams, Link, useLocation } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@mui/material";
import DeleteOutlineOutlined from "@mui/icons-material/DeleteOutlineOutlined";
import AddItem from "../../../../Components/Admin/AddItem/AddItem";
import ConfirmDialog from "../../../../Components/Admin/ConfirmDialog/ConfirmDialog";

function CategoryDetails() {
  const { categoryId } = useParams();
  const [subcategories, setSubcategories] = useState([]);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [category, setCategory] = useState(null);
  const [addItemOpen, setAddItemOpen] = useState(false);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const name = queryParams.get("name");

  const apiUrl = "https://backend.balloonflower.kyiv.ua";
  const token = localStorage.getItem("auth_token");

  useEffect(() => {
    fetch(`${apiUrl}/api/categories/${categoryId}`)
      .then((response) => response.json())
      .then((data) => {
        console.log("Fetched data:", data);
        if (data) {
          setCategory(data);
          if (data.subcategory) {
            setSubcategories(data.subcategory);
          }
        } else {
          console.error("Unexpected data format:", data);
        }
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, [categoryId]);

  const handleDeleteItem = (id) => {
    fetch(`${apiUrl}/api/categories`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        token: `${token}`,
      },
      body: JSON.stringify({ id }),
    })
      .then(() => {
        setSubcategories(subcategories.filter((item) => item.id !== id));
        setConfirmDialogOpen(false);
        setDeleteId(null);
      })
      .catch((error) => console.error("Error deleting data:", error));
  };

  const handleSaveItem = (updatedData) => {
    fetch(`${apiUrl}/api/categories`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        token: `${token}`,
      },
      body: JSON.stringify(updatedData),
    })
      .then((response) => response.json())
      .then(() => {
        setSubcategories(
          subcategories.map((item) =>
            item.id === updatedData.id ? updatedData : item
          )
        );
      })

      .catch((error) => console.error("Error updating data:", error));
  };

  const handleAddItem = (newData) => {
    fetch(`${apiUrl}/api/categories/sub`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        token: `${token}`,
      },
      body: JSON.stringify(newData),
    })
      .then((response) => response.json())
      .then((newItem) => {
        setSubcategories([...subcategories, newItem]);
        setAddItemOpen(false);
      })
      .catch((error) => console.error("Error adding data:", error));
  };

  const openConfirmDialog = (id) => {
    setDeleteId(id);
    setConfirmDialogOpen(true);
  };

  const closeConfirmDialog = () => {
    setConfirmDialogOpen(false);
    setDeleteId(null);
  };

  const confirmDelete = () => {
    if (deleteId) {
      handleDeleteItem(deleteId);
    }
  };

  return (
    <>
      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center gap-3">
          <Link to="/admin/categorylist">
            <Button variant="contained" color="primary" size="small">
              Назад
            </Button>
          </Link>
          <h4>Подкатегории для {name}</h4>
        </div>
        <AddItem
          open={addItemOpen}
          onClose={() => setAddItemOpen(false)}
          onSave={handleAddItem}
          initialData={{ parent_id: categoryId, title: "" }}
          buttonText={"Добавить подкатегорию"}
        />
      </div>
      <TableContainer component={Paper} title="Подкатегории">
        <Table
          sx={{ minWidth: 650 }}
          title="Подкатегории"
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell>№</TableCell>
              <TableCell align="right">Подкатегория</TableCell>
              <TableCell align="right">Действие</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {subcategories.map((row, index) => (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {index + 1}
                </TableCell>
                <TableCell align="right">{row.title}</TableCell>
                <TableCell align="right">
                  <div className="flex justify-end gap-2">
                    <Button
                      onClick={() => openConfirmDialog(row.id)}
                      variant="contained"
                      color="error"
                      size="small"
                    >
                      <DeleteOutlineOutlined />
                    </Button>
                    <AddItem
                      initialData={row}
                      onSave={handleSaveItem}
                      buttonText={"EDIT"}
                    />
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ConfirmDialog
        open={confirmDialogOpen}
        onClose={closeConfirmDialog}
        onConfirm={confirmDelete}
        title="Confirm Delete"
        content="Are you sure you want to delete this item?"
      />
    </>
  );
}

export default CategoryDetails;
