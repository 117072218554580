import "./AddProductColor.css";
import { useEffect, useState } from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  DialogTitle,
  DialogActions,
  DialogContent,
  Dialog,
  Button,
} from "@mui/material";

const AddProductColor = ({ buttonText, product_color, productId }) => {
  const [color, setColor] = useState(null);
  const [edited, setEdited] = useState(false);
  const [file, setFile] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectColor, setSelectColor] = useState([]);

  const handleChange = (event) => {
    setColor(event.target.value);
  };

  const handleFileChange = (event) => {
    setEdited(true);
    setFile(event.target.files[0]);
  };

  const handleClickOpen = () => {
    setOpen(true);
    if (product_color) {
      fetch(`https://backend.balloonflower.kyiv.ua/api/product/color/one/${product_color}`)
        .then((response) => response.json())
        .then((data) => {
          setColor(data[0].color_id);
          setFile(data[0].photo);
        });
    }
  };

  const handleClose = () => {
    setOpen(false);
    setFile(null);
    setColor([]);
  };

  const editProductColor = () => {
    const formdata = new FormData();
    formdata.append("color_id", color);
    formdata.append("photos", file);
    formdata.append("id", product_color);

    fetch(`https://backend.balloonflower.kyiv.ua/api/product/color`, {
      method: "PUT",
      headers: {
        token: localStorage.getItem("auth_token"),
      },
      body: formdata,
    })
      .then((response) => response.json())
      .then((data) => window.location.reload());
  };

  const saveProductColor = () => {
    const formdata = new FormData();
    formdata.append("color_id", color);
    formdata.append("photos", file);
    formdata.append("product_id", productId);

    fetch(`https://backend.balloonflower.kyiv.ua/api/product/color`, {
      method: "POST",
      headers: {
        token: localStorage.getItem("auth_token"),
      },
      body: formdata,
    })
      .then((response) => response.json())
      .then((data) => window.location.reload());
  };

  useEffect(() => {
    fetch(`https://backend.balloonflower.kyiv.ua/api/colors`)
      .then((response) => response.json())
      .then((data) => setSelectColor(data));
  }, []);

  return (
    <>
      <Button variant="outlined" onClick={handleClickOpen}>
        {buttonText}
      </Button>
      <Dialog
        open={open}
        fullWidth
        onClose={handleClose}
        PaperProps={{
          component: "form",
          onSubmit: (event) => {
            event.preventDefault();
            if (product_color) {
              editProductColor();
            } else {
              saveProductColor();
            }
            handleClose();
          },
        }}
      >
        <DialogTitle>{buttonText}</DialogTitle>
        <DialogContent>
          <div className="flex flex-col gap-2">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Colors</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={color}
                label="Color"
                name="color"
                onChange={handleChange}
              >
                {selectColor.map((item, index) => (
                  <MenuItem key={index} value={item.id}>
                    <div className="flex items-center gap-2">
                      <div
                        style={{
                          backgroundColor: item.color,
                          width: 40,
                          height: 40,
                        }}
                      ></div>
                      <p>{item.color}</p>
                    </div>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {file && (!product_color || edited) ? (
              <img
                className="w-full h-40 object-contain"
                src={URL.createObjectURL(file)}
                alt="preview"
              />
            ) : file ? (
              <img
                className="w-full h-40 object-contain"
                src={`https://backend.balloonflower.kyiv.ua/uploads/${file}`}
                alt="preview"
              />
            ) : null}
            <Button
              component="label"
              variant="contained"
              startIcon={<CloudUploadIcon />}
            >
              Загрузить изображение
              <input
                onChange={handleFileChange}
                name="photo"
                className="hidden"
                type="file"
              />
            </Button>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Отменить</Button>
          <Button type="submit">Принять</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddProductColor;
