import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import AddSlider from "../../../Components/Admin/AddSlider/AddSlider";
import ConfirmDialog from "../../../Components/Admin/ConfirmDialog/ConfirmDialog";

function HeroSliderList() {
  const [sliderData, setSliderData] = useState([]);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [addSliderOpen, setAddSliderOpen] = useState(false);

  const apiUrl = "https://backend.balloonflower.kyiv.ua";
  const token = localStorage.getItem("auth_token");

  useEffect(() => {
    fetch(`${apiUrl}/api/slider`)
      .then((response) => response.json())
      .then((data) => setSliderData(data))
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const handleDelete = (id) => {
    fetch(`${apiUrl}/api/slider/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        token: `${token}`,
      },
    })
      .then(() => {
        setSliderData(sliderData.filter((item) => item.id !== id));
        setConfirmDialogOpen(false);
        setDeleteId(null);
      })
      .catch((error) => console.error("Error deleting data:", error));
  };

  const handleSave = (updatedData) => {
    fetch(`${apiUrl}/api/slider`, {
      method: "PUT",
      headers: {
        token: `${token}`,
      },
      body: updatedData,
    })
      .then((response) => response.json())
      .then(() => {
        fetch(`${apiUrl}/api/slider`)
          .then((response) => response.json())
          .then((data) => setSliderData(data))
          .catch((error) => console.error("Error fetching data:", error));
      })
      .catch((error) => console.error("Error updating data:", error));
  };

  const handleAdd = (newData) => {
    fetch(`${apiUrl}/api/slider`, {
      method: "POST",
      headers: {
        token: `${token}`,
      },
      body: newData,
    })
      .then((response) => response.json())
      .then((newItem) => {
        setSliderData([...sliderData, newItem]);
        setAddSliderOpen(false);
      })
      .catch((error) => console.error("Error adding data:", error));
  };

  const openConfirmDialog = (id) => {
    setDeleteId(id);
    setConfirmDialogOpen(true);
  };

  const closeConfirmDialog = () => {
    setConfirmDialogOpen(false);
    setDeleteId(null);
  };

  const confirmDelete = () => {
    if (deleteId) {
      handleDelete(deleteId);
    }
  };

  return (
    <section>
      <div className="container">
        <div className="flex flex-col gap-[40px]">
          <div className="HEAD flex justify-between">
            <div>
              <h2>Hero Slider</h2>
              <p>Slider items displayed on the main page</p>
            </div>
            <div>
              <AddSlider
                open={addSliderOpen}
                onClose={() => setAddSliderOpen(false)}
                onSave={handleAdd}
                initialData={{
                  photo: "",
                  title: "",
                  description: "",
                }}
                buttonText="Добавить новый элемент слайдера"
              />
            </div>
          </div>

          <div>
            <TableContainer component={Paper}>
              <Table aria-label="hero slider table">
                <TableHead>
                  <TableRow>
                    <TableCell>Фотография</TableCell>
                    <TableCell>Титуль</TableCell>
                    <TableCell>Описание</TableCell>
                    <TableCell>Действие</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sliderData.map((item) => (
                    <TableRow key={item.id}>
                      <TableCell>
                        <img
                          className="w-[60px]"
                          src={`${apiUrl}/uploads/${item.photo}`}
                          alt={item.title}
                        />
                      </TableCell>
                      <TableCell>{item.title}</TableCell>
                      <TableCell>{item.description}</TableCell>
                      <TableCell>
                        <AddSlider
                          initialData={item}
                          onSave={handleSave}
                          slider_id={item.id}
                        />
                        <Button
                          sx={{ marginLeft: "4px", marginTop: "4px" }}
                          variant="outlined"
                          onClick={() => openConfirmDialog(item.id)}
                        >
                          <DeleteOutlineOutlinedIcon />
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>

      <ConfirmDialog
        open={confirmDialogOpen}
        onClose={closeConfirmDialog}
        onConfirm={confirmDelete}
        title="Confirm Delete"
        content="Are you sure you want to delete this item?"
      />
    </section>
  );
}

export default HeroSliderList;
