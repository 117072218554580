import React from 'react';
import { Breadcrumbs, Typography } from '@mui/material';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import './BasicBreadcrumbs.css';
import { Link } from 'react-router-dom';

function BasicBreadcrumbs({ product }) {
  return (
    <div role="presentation" className='BasicBreadcrumbs'>
      <Breadcrumbs aria-label="breadcrumb" separator="›">
        <Link underline="hover" color="inherit" to="/">
          <HomeOutlinedIcon fontSize="inherit" />
        </Link>
        <Link
          underline="hover"
          color="inherit"
          to="/shop/"
        >
          Каталог
        </Link>
        <Typography color="text.primary">{product ? product.title : 'Загрузка...'}</Typography>
      </Breadcrumbs>
    </div>
  );
}

export default BasicBreadcrumbs;
