const checkAuth = async () => {
  const token = localStorage.getItem("auth_token");
  const apiUrl = "https://backend.balloonflower.kyiv.ua";

  if (token) {
    const data = await fetch(apiUrl + '/api/user', {
        method: "GET",
        headers: {
            token: token
        }
    });

    if(data.status !== 200){
        return window.location.href = '/login'
    }
  } else {
    return window.location.href = "/login";
  }
};

export default checkAuth