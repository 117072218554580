import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";

function AddItem({ initialData = {}, categories = [], onSave, buttonText = "Edit" }) {
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    id: initialData.id || "",
    parent_id: initialData.parent_id || "",
    title: initialData.title || "",
  });
  const [formErrors, setFormErrors] = useState({
    title: false,
    // parent_id: false,
  });

  useEffect(() => {
    if (open) {
      setFormData(initialData);
    }
  }, [open, initialData]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ 
      ...formData, 
      [name]: name === "parent_id" ? Number(value) : value 
    });
  };

  const handleSave = () => {
    const errors = {};
    let hasErrors = false;
    if (!formData.title) {
      errors.title = true;
      hasErrors = true;
    } else {
      errors.title = false;
    }
    if (!formData.parent_id) {
      errors.parent_id = true;
      hasErrors = true;
    } else {
      errors.parent_id = false;
    }
    setFormErrors(errors);
  
    if (!hasErrors) {
      const dataToSave = {
        ...formData,
        parent_id: Number(formData.parent_id)
      };
      onSave(dataToSave);
      handleClose();
      
    }
  };

  return (
    <div>
      <Button
        sx={{ marginLeft: "4px", marginTop: "4px" }}
        variant="outlined"
        onClick={handleClickOpen}
      >
        {buttonText}
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{buttonText} Item</DialogTitle>
        <DialogContent>
          {/* <FormControl fullWidth margin="dense">
            <InputLabel>Category</InputLabel>
            <Select
              name="parent_id"
              value={formData.parent_id}
              onChange={handleChange}
              error={formErrors.parent_id}
              label="Category"
            >
              {categories.map((category) => (
                <MenuItem key={category.id} value={category.id}>
                  {category.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl> */}
          <TextField
            autoFocus
            margin="dense"
            name="title"
            label="title"
            type="text"
            fullWidth
            value={formData.title}
            onChange={handleChange}
            required={true}
            error={formErrors.title}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSave}>Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AddItem;