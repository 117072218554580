
import { Link } from 'react-router-dom'
import './Footer.css'
import FooterLogo from '../../Assets/Icons/logoText.svg'
import SocialIcon from '../All/SocialIcon/SocialIcon'
import Skoyar from '../../Assets/Icons/Skoyar.svg'

function Footer() {

  return (
    <>
    <footer className='Footer'>
        <div className='container'>
            <div className='FooterWrapper'>
                <div className='FooterCol'>
                    <p className='FooterTitle'>Меню</p>
                    <div className='FooterList'>
                        <Link className='FooterItem' to='/'>Головна</Link>
                        <Link className='FooterItem' to='/search'>Каталог</Link>
                        <Link className='FooterItem' to='/about'>Про нас</Link>
                        <Link className='FooterItem' to='/contacts'>Контакти</Link>
                    </div>
                </div>
                <div className='FooterCol'>
                    <p className='FooterTitle'>Кульки</p>
                    <div className='FooterList'>
                        <a href='/shop/Tvarinki' className='FooterItem' >Тваринки</a>
                        <a href='/shop/Den%20narodzhennya' className='FooterItem' >День народження</a>
                        <a href='/shop/Cifri' className='FooterItem' >Цифри</a>
                        <a href='/shop/Z%20konfeti' className='FooterItem' >З конфеті</a>
                        <a href='/shop/Vipiska%20z%20roddomu' className='FooterItem' >Виписка з роддому</a>
                    </div>
                </div>
                <div className='FooterCol'>
                    <p className='FooterTitle'>Квіти</p>
                    <div className='FooterList'>
                        <a className='FooterItem' href='/shop/Monobuketi'>монобукети</a>
                        <a className='FooterItem' href='/shop/Buketi%20zbirni'>букети збірні</a>
                        <a className='FooterItem' href='/shop/Kviti%20v%20korobci'>квіти в коробці</a>
                        <a className='FooterItem' href='/shop/Mini%20buketi'>міні букети</a>
                    </div>
                </div>
                <div className='FooterCol'>
                    <p className='FooterTitle last'>
                        <img className='w-full max-w-[220px]' src={FooterLogo} alt=''/>
                    </p>
                    <div className='FooterList last'>
                        <SocialIcon />
                        <Link className='FooterItem last' to='/privat-policy'>Політика Конфіденсійності</Link>
                        <Link className='FooterItem last' to='/privat-policy'>Договір оферти</Link>
                        <span className='FooterItem last' to='/'>All rights reserved © 2024 "Balloon&Flower"</span>
                    </div>
                </div>
            </div>
        </div>
        
    </footer>
    <div className='Skoyar'>
        <a href='https://skoyar.com/'><img src={Skoyar}/></a>
    </div>
</>
  )
}

export default Footer