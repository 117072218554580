import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  ListSubheader,
} from "@mui/material";

import "./AddShopProduct.css";

function AddShopProduct({ initialData = {}, onSave, buttonText = "Edit" }) {
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    id: initialData.id || "",
    title: initialData.title || "",
    description: initialData.description || "",
    price: initialData.price || "",
    price_old: initialData.price_old || "",
    category_id: initialData.category_id || "",
  });
  const [formErrors, setFormErrors] = useState({
    title: false,
    description: false,
    price: false,
    price_old: false,
    category_id: false,
  });
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    if (open) {
      setFormData({
        ...initialData,
      });
    }
  }, [open, initialData]);

  useEffect(() => {
    fetch("https://backend.balloonflower.kyiv.ua/api/categories")
      .then((response) => response.json())
      .then((data) => {
        // Объединение категорий и подкатегорий
        const mergedCategories = data.category.map((category) => ({
          ...category,
          subcategories: data.subcategory.filter(
            (subcategory) => subcategory.parent_id === category.id.toString()
          ),
        }));
        setCategories(mergedCategories);
      })
      .catch((error) => console.error("Error fetching categories:", error));
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSave = () => {
    const errors = {};
    let hasErrors = false;

    setFormErrors(errors);

    if (!hasErrors) {
      console.log("Form Data before Save:", formData);
      onSave(formData);
      handleClose();
    }
  };

  return (
    <div>
      <Button
        sx={{ marginLeft: "4px", marginTop: "4px" }}
        variant="outlined"
        onClick={handleClickOpen}
      >
        {buttonText}
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{buttonText} Product</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            name="title"
            label="Title"
            type="text"
            fullWidth
            value={formData.title}
            onChange={handleChange}
            required={true}
            error={formErrors.title}
          />
          <TextField
            margin="dense"
            name="description"
            label="Description"
            type="text"
            fullWidth
            value={formData.description}
            onChange={handleChange}
            required={true}
            error={formErrors.description}
          />
          <TextField
            margin="dense"
            name="price"
            label="Price"
            type="number"
            fullWidth
            value={formData.price}
            onChange={handleChange}
            required={true}
            error={formErrors.price}
          />
          <TextField
            margin="dense"
            name="price_old"
            label="Old Price"
            type="number"
            fullWidth
            value={formData.price_old}
            onChange={handleChange}
            required={true}
            error={formErrors.price_old}
          />
          <FormControl fullWidth margin="dense">
            <InputLabel>Category</InputLabel>
            <Select
              name="category_id"
              value={formData.category_id}
              onChange={handleChange}
              error={formErrors.category_id}
              label="Category"
            >
              {categories.flatMap((category) => [
                <ListSubheader
                  key={category.id}
                  sx={{ color: "#500056", fontWeight: "bold", fontSize: "1.2rem" }}
                >
                  {category.title}
                </ListSubheader>,
                ...category.subcategories.map((subcategory) => (
                  <MenuItem key={subcategory.id} value={subcategory.id}>
                    {subcategory.title}
                  </MenuItem>
                )),
              ])}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSave}>Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AddShopProduct;
