import { Stack, Pagination } from "@mui/material";
import "./CustomPagination.css";

function CustomPagination({ onPageChange, pageCount, currentPage }) {
  return (
    <div className="CustomPagination">
      <Stack>
        <Pagination 
          count={pageCount} 
          color="secondary" 
          onChange={onPageChange} 
          page={currentPage} 
        />
      </Stack>
    </div>
  );
}

export default CustomPagination;