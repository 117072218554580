import TitleSection from '../../All/Title/TitleSection'

import PopularArrow from '../../../Assets/Icons/popularArrow.svg'
import LatexCard1 from "../../../Assets/Img/VisualCatalog/Latex/1.webp"
import LatexCard2 from "../../../Assets/Img/VisualCatalog/Latex/2.webp"
import LatexCard3 from "../../../Assets/Img/VisualCatalog/Latex/3.webp"
import LatexCard4 from "../../../Assets/Img/VisualCatalog/Latex/4.webp"
import LatexCard5 from "../../../Assets/Img/VisualCatalog/Latex/5.webp"
import LatexCard6 from "../../../Assets/Img/VisualCatalog/Latex/6.webp"
import LatexCard7 from "../../../Assets/Img/VisualCatalog/Latex/7.webp"


function Latex() {
    return (
        <section className='PopularCategory'>
            <div className='container'>
                <TitleSection title="Латексні" />
                <div className='PopularWrapper'>
                    <div className='PopularRow'>
                        <div className='PopularCol'>
                            <div className='PopularCard'>
                                <img src={LatexCard1} alt='' className='PopularImg'/>
                                <div className='PopularContent'>
                                    <h3 className=''>Пастель</h3>
                                    <a href='/shop/Pastel'>
                                        <p>Детальніше</p>
                                        <img src={PopularArrow} alt='' className='PopularIcon'/>
                                    </a>
                                </div>
                            </div>
                            <div className='PopularCard'>
                                <img src={LatexCard2} alt='' className='PopularImg'/>
                                <div className='PopularContent'>
                                    <h3 className=''>Перламутр</h3>
                                    <a href='/shop/Perlamutr'>
                                        <p>Детальніше</p>
                                        <img src={PopularArrow} alt='' className='PopularIcon'/>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className='PopularCol'>
                            <div className='PopularCard'>
                                <img src={LatexCard3} alt='' className='PopularImg'/>
                                <div className='PopularContent'>
                                    <h3 className=''>З конфеті</h3>
                                    <a href='/shop/Z%20konfeti'>
                                        <p>Детальніше</p>
                                        <img src={PopularArrow} alt='' className='PopularIcon'/>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='PopularRow'>
                        <div className='PopularCol'>
                            <div className='PopularCard'>
                                <img src={LatexCard4} alt='' className='PopularImg'/>
                                <div className='PopularContent'>
                                    <h3 className='text-white'>Хром</h3>
                                    <a href='/shop/Hrom'>
                                        <p>Детальніше</p>
                                        <img src={PopularArrow} alt='' className='PopularIcon'/>
                                    </a>
                                </div>
                            </div>
                            <div className='PopularCard'>
                                <img src={LatexCard5} alt='' className='PopularImg'/>
                                <div className='PopularContent'>
                                    <h3 className=''>Агати</h3>
                                    <a href='/shop/Agati'>
                                        <p>Детальніше</p>
                                        <img src={PopularArrow} alt='' className='PopularIcon'/>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className='PopularCol'>
                            <div className='PopularCard'>
                                <img src={LatexCard6} alt='' className='PopularImg'/>
                                <div className='PopularContent'>
                                    <h3 className=''>З малюнком</h3>
                                    <a href='/shop/Z%20malyunkom'>
                                        <p>Детальніше</p>
                                        <img src={PopularArrow} alt='' className='PopularIcon'/>
                                    </a>
                                </div>
                            </div>
                            <div className='PopularCard'>
                                <img src={LatexCard7} alt='' className='PopularImg'/>
                                <div className='PopularContent'>
                                    <h3 className=''>З написами</h3>
                                    <a href='/shop/Z%20napisami'>
                                        <p>Детальніше</p>
                                        <img src={PopularArrow} alt='' className='PopularIcon'/>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
  
export default Latex