import './NotFound.css'

import NotFoundImg from "../../Assets/Icons/404.svg"


function NotFound() {

  return (
  <>
    <section className=''>
      <div className='container flex flex-col items-center gap-[40px] min-h-[80vh] justify-center'>
        <img src={NotFoundImg} alt='Not Found' />
        <a className='button' href='/'>На головну</a>
      </div>
    </section>
  </>
  )
}

export default NotFound