import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@mui/material";
import { Link, useParams } from "react-router-dom";

const apiUrl = 'https://backend.balloonflower.kyiv.ua';

export default function BasicTable() {
  const { requestId } = useParams("requestId");
  const [orders, setOrders] = useState([]);
  const [colors, setColors] = useState([]);

  useEffect(() => {
    // Fetch orders
    fetch(`${apiUrl}/api/requests/${requestId}`)
      .then((res) => res.json())
      .then((data) => {
        // console.log("Заказы", data.orders);
        setOrders(data.orders);
      });

    // Fetch colors
    fetch(`${apiUrl}/api/colors`)
      .then((res) => res.json())
      .then((data) => {
        // console.log("Цвета", data);
        setColors(data);
      });
  }, [requestId]);

  // Function to get color by color_id
const getColorById = (color_id) => {
  console.log("Получен color_id:", color_id); // Логирование входного color_id

  if (!color_id) {
    console.log("color_id отсутствует или неверный:", color_id);
    return "Unknown";
  }

  console.log("Массив цветов для поиска:", colors); // Логирование массива цветов

  const color = colors.find((color) => {
    console.log("Сравнение color.id:", color.id, "с color_id:", color_id); // Логирование каждого сравнения
    return color.id === color_id;
  });

  console.log("Найденный цвет:", color); // Логирование результата поиска

  return color ? color.color : "Unknown";
};


  return (
    <>
      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center gap-3">
          <Link to="/admin/requests">
            <Button variant="contained" color="primary" size="small">
              Назад
            </Button>
          </Link>
          <h4>Детали заказа</h4>
        </div>
      </div>
      <TableContainer component={Paper} title="Детали заказа">
        <Table
          sx={{ minWidth: 650 }}
          title="Детали заказа"
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell>№</TableCell>
              <TableCell align="right">Продукт</TableCell>
              <TableCell align="right">Цвет</TableCell>
              <TableCell align="right">Описание</TableCell>
              <TableCell align="right">Цена</TableCell>
              <TableCell align="right">Количество</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders.map((row, index) => (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {index + 1}
                </TableCell>
                <TableCell align="right">{row.title}</TableCell>
                <TableCell align="right">
                  <div className="flex items-center justify-end gap-2">
                    <div
                      className="w-10 h-10"
                      style={{ backgroundColor: row.color }}
                    ></div>
                  
                  </div>
                </TableCell>
                <TableCell align="right">{row.description}</TableCell>
                <TableCell align="right">{row.price}</TableCell>
                <TableCell align="right">{row.count}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}