import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  IconButton,
} from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import AddCategory from "../../../Components/Admin/AddCategory/AddCategory";
import AddItem from "../../../Components/Admin/AddItem/AddItem";
import ConfirmDialog from "../../../Components/Admin/ConfirmDialog/ConfirmDialog";
import { Link } from "react-router-dom";

function CategoryList() {
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [addCategoryOpen, setAddCategoryOpen] = useState(false);
  const [addItemOpen, setAddItemOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const apiUrl = "https://backend.balloonflower.kyiv.ua";
  const token = localStorage.getItem("auth_token");

  useEffect(() => {
    fetch(`${apiUrl}/api/categories`)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.category && data.subcategory) {
          setCategories(data.category);
          setSubcategories(data.subcategory);
        } else {
          console.error("Unexpected data format:", data);
        }
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const handleDeleteCategory = (id) => {
    fetch(`${apiUrl}/api/categories`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        token: `${token}`,
      },
      body: JSON.stringify({ id }),
    })
      .then(() => {
        setCategories(categories.filter((item) => item.id !== id));
        setConfirmDialogOpen(false);
        setDeleteId(null);
      })
      .catch((error) => console.error("Error deleting data:", error));
  };

  const handleDeleteItem = (id) => {
    fetch(`${apiUrl}/api/categories`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        token: `${token}`,
      },
      body: JSON.stringify({ id }),
    })
      .then(() => {
        setSubcategories(subcategories.filter((item) => item.id !== id));
        setConfirmDialogOpen(false);
        setDeleteId(null);
      })
      .catch((error) => console.error("Error deleting data:", error));
  };

  const handleSaveCategory = (updatedData) => {
    fetch(`${apiUrl}/api/categories`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        token: `${token}`,
      },
      body: JSON.stringify(updatedData),
    })
      .then((response) => response.json())
      .then(() => {
        setCategories(
          categories.map((item) =>
            item.id === updatedData.id ? updatedData : item
          )
        );
      })
      .catch((error) => console.error("Error updating data:", error));
  };

  const handleSaveItem = (updatedData) => {
    fetch(`${apiUrl}/api/categories/`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        token: `${token}`,
      },
      body: JSON.stringify(updatedData),
    })
      .then((response) => response.json())
      .then(() => {
        setSubcategories(
          subcategories.map((item) =>
            item.id === updatedData.id ? updatedData : item
          )
        );
      })
      .catch((error) => console.error("Error updating data:", error));
  };

  const handleAddCategory = (newData) => {
    fetch(`${apiUrl}/api/categories`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        token: `${token}`,
      },
      body: JSON.stringify(newData),
    })
      .then((response) => response.json())
      .then((newItem) => {
        setCategories([...categories, newItem]);
        setAddCategoryOpen(false);
      })
      .catch((error) => console.error("Error adding data:", error));
  };

  const handleAddItem = (newData) => {
    fetch(`${apiUrl}/api/categories/sub`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        token: `${token}`,
      },
      body: JSON.stringify(newData),
    })
      .then((response) => response.json())
      .then((newItem) => {
        setSubcategories([...subcategories, newItem]);
        setAddItemOpen(false);
      })
      .catch((error) => console.error("Error adding data:", error));
  };

  const openConfirmDialog = (id) => {
    setDeleteId(id);
    setConfirmDialogOpen(true);
  };

  const closeConfirmDialog = () => {
    setConfirmDialogOpen(false);
    setDeleteId(null);
  };

  const confirmDelete = () => {
    if (deleteId) {
      handleDeleteCategory(deleteId);
    }
  };

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };

  return (
    <>
      <section>
        <div className="container">
          <div className="flex flex-col gap-[40px]">
            <div className="HEAD flex justify-between">
              <div>
                <h2>Категорії(заголовки)</h2>
                <p>тут редагуються головні группи</p>
              </div>
              <div>
                <AddCategory
                  open={addCategoryOpen}
                  onClose={() => setAddCategoryOpen(false)}
                  onSave={handleAddCategory}
                  initialData={{ title: "" }}
                  buttonText="Добавити нову категорію"
                />
              </div>
            </div>
            <div>
              <TableContainer component={Paper}>
                <Table aria-label="categories table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Category</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Array.isArray(categories) &&
                      categories.map((item) => (
                        <TableRow key={item.id}>
                          <TableCell>
                            <Link
                              to={`/admin/category/${item.id}?name=${item.title}`}
                              className="underline"
                            >
                              {item.title}
                            </Link>
                          </TableCell>
                          <TableCell>
                            <AddCategory
                              initialData={item}
                              onSave={handleSaveCategory}
                            />
                            <Button
                              sx={{ marginLeft: "4px", marginTop: "4px" }}
                              variant="outlined"
                              onClick={() => openConfirmDialog(item.id)}
                            >
                              <DeleteOutlineOutlinedIcon />
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </div>

        <ConfirmDialog
          open={confirmDialogOpen}
          onClose={closeConfirmDialog}
          onConfirm={confirmDelete}
          title="Confirm Delete"
          content="Are you sure you want to delete this item?"
        />
      </section>
      {selectedCategory && (
        <section>
          <div className="container">
            <div className="flex flex-col gap-[40px]">
              <div className="HEAD flex justify-between">
                <div>
                  <h2>Підкатегорії для {selectedCategory.title}</h2>
                  <p>тут редагуються підкатегорії</p>
                </div>
                <div>
                  <AddItem
                    open={addItemOpen}
                    onClose={() => setAddItemOpen(false)}
                    onSave={handleAddItem}
                    categories={categories}
                    initialData={{ parent_id: selectedCategory.id, title: "" }}
                    buttonText="Добавити новий елемент"
                  />
                </div>
              </div>
              <div>
                <TableContainer component={Paper}>
                  <Table aria-label="items table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Parent Category</TableCell>
                        <TableCell>Subcategory</TableCell>
                        <TableCell>Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Array.isArray(subcategories) &&
                        subcategories
                          .filter(
                            (item) => item.parent_id === selectedCategory.id
                          )
                          .map((item) => (
                            <TableRow key={item.id}>
                              <TableCell>
                                {categories.find(
                                  (cat) => cat.id === item.parent_id
                                )?.title || "None"}
                              </TableCell>
                              <TableCell>
                                <Link
                                  to={`/admin/subcategory/${item.id}`}
                                  className="underline"
                                >
                                  {item.title}
                                </Link>
                              </TableCell>
                              <TableCell>
                                <AddItem
                                  initialData={item}
                                  onSave={handleSaveItem}
                                  categories={categories}
                                />
                                <Button
                                  sx={{ marginLeft: "4px", marginTop: "4px" }}
                                  variant="outlined"
                                  onClick={() => openConfirmDialog(item.id)}
                                >
                                  <DeleteOutlineOutlinedIcon />
                                </Button>
                              </TableCell>
                            </TableRow>
                          ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          </div>

          <ConfirmDialog
            open={confirmDialogOpen}
            onClose={closeConfirmDialog}
            onConfirm={confirmDelete}
            title="Confirm Delete"
            content="Are you sure you want to delete this item?"
          />
        </section>
      )}
    </>
  );
}

export default CategoryList;
