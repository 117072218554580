import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Checkbox,
  ListItemText,
} from "@mui/material";
import "./FilterSelect.css";
import { usePriceFilter } from "../../../../context/PriceFilterContext";
import CardBallon from "../../Shop/ShopCard/CardBallon/CardBallon";

function FilterSelect({ isHidden }) {
  const {
    sortOrder,
    setSortOrder,
    selectedColors,
    setSelectedColors,
    availableColors,
  } = usePriceFilter();

  const handleSortChange = (event) => {
    setSortOrder(event.target.value);
  };

  const handleColorChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedColors(typeof value === "string" ? value.split(",") : value);
  };

  return (
    <Box
      className={`FilterSelect ${
        isHidden ? "hidden sm:block" : "block sm:hidden"
      }`}
    >
      <div className="flex gap-[5px] sm:gap-[20px]">
        <FormControl fullWidth className="FilterPrice">
          <InputLabel>Сортувати</InputLabel>
          <Select
            label="Сортувати"
            value={sortOrder}
            onChange={handleSortChange}
          >
            <MenuItem value={null}>Не вибрано</MenuItem>
            <MenuItem value="asc">Від дешевих до дорогих</MenuItem>
            <MenuItem value="desc">Від дорогих до дешевих</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth  className="FilterColor">
          <InputLabel>Кольори</InputLabel>
          <Select
            multiple
            label="Кольори"
            value={selectedColors}
            onChange={handleColorChange}
            renderValue={(selected) => selected.join(", ")}
          >
            {availableColors.map((color) => (
              <MenuItem key={color.id} value={color.color}>
                <Checkbox checked={selectedColors.indexOf(color.color) > -1} />
                <ListItemText />
                <div className="w-full flex justify-center">
                <CardBallon
                  color={color.color}
                />

                </div>
                
                
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </Box>
  );
}

export default FilterSelect;
